import fontThin from '../assets/fonts/UniversNextforHSBC-Thin.ttf';
import fontLight from '../assets/fonts/UniversNextforHSBC-Light.ttf';
import fontRegular from '../assets/fonts/UniversNextforHSBC-Regular.ttf';
import fontMedium from '../assets/fonts/UniversNextforHSBC-Medium.ttf';
import { Font } from '@react-pdf/renderer';
import spending from 'assets/pdf/icons/spending.png';
import borrowing from 'assets/pdf/icons/borrowing.png';
import saving from 'assets/pdf/icons/saving.png';
import umbrella from 'assets/pdf/icons/umbrella.png';
import secure from 'assets/pdf/icons/secure.png';
import light from 'assets/pdf/icons/light.png';
import car from 'assets/pdf/icons/car.png';
import childcare from 'assets/pdf/icons/childcare.png';
import counciltax from 'assets/pdf/icons/counciltax.png';
import groceries from 'assets/pdf/icons/groceries.png';
import publicTransport from 'assets/pdf/icons/publicTransport.png';
import mediaEntertainment from 'assets/pdf/icons/mediaEntertainment.png';
import union from 'assets/pdf/icons/Union.png';
import utilities from 'assets/pdf/icons/utilities.png';
import other from 'assets/pdf/icons/other.png';
import newWindow from 'assets/pdf/icons/new_window.png';
import arrow from 'assets/pdf/icons/arrow.png';
import housing from 'assets/pdf/icons/housing.png';
import savingRed from 'assets/pdf/icons/saving-red.png';
import spendingRed from 'assets/pdf/icons/spending-red.png';
import borrowingRed from 'assets/pdf/icons/borrowing-red.png';
import plan from 'assets/pdf/icons/plan.png';
import phone from 'assets/pdf/icons/phone.png';
import new_window from 'assets/pdf/icons/new_window.png';

const ICONS = {
  spending,
  borrowing,
  saving,
  umbrella,
  secure,
  light,
  car,
  childcare,
  counciltax,
  groceries,
  publicTransport,
  mediaEntertainment,
  union,
  utilities,
  other,
  newWindow,
  arrow,
  housing,
  savingRed,
  spendingRed,
  borrowingRed,
  plan,
  phone,
  new_window
};

export const registerPdfFonts = () => {
  Font.register({
    family: 'UniversNextForHSBC',
    fonts: [
      // font-weight: 100
      { src: fontThin, fontWeight: 'thin' },
      // font-weight: 300
      { src: fontLight, fontWeight: 'light' },
      // font-weight: 400
      { src: fontRegular, fontWeight: 'normal' },
      // font-weight: 500
      { src: fontMedium, fontWeight: 'medium' },
    ],
  });
  // Disable word hyphenation
  Font.registerHyphenationCallback((word) => [word]);
};

export const getIcon = (icon) => ICONS[icon];
