import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { ANALYTICS } from 'constants/analytics';
import { useStepData } from 'hooks';
import { trackView } from 'services/tms';
import { getMonthlyValue, getWeeklyValue } from 'utils/util';
import store from '../../../store';

import Input from '../../Input/Input';
import Step from '../Step';

const STEP_ID = '5';
const PREV_STEP = '4';

const Step5 = (props) => {
  const { t } = props;
  const defaultState = {
    monthlyIncome: store.getState().survey.monthlyIncome ? store.getState().survey.monthlyIncome :'',
    incomeFrequency: 'monthly',
  };

  // If we already went through this step, get the data from this step
  // If not, the state will initialize with the default empty state
  const stepData = useStepData(STEP_ID);
  const data = stepData || defaultState;

  // And set it to the state
  const [state, setState] = useState(data);

  // Additional state to check if the next button is disabled
  const [nextDisabled, setNextDisabled] = useState(true);
  const [income, setIncome] = useState(defaultState.monthlyIncome);

  const updateStepData = (e) => {
    let value = e.target.value !== '' ? (e.target.value).replaceAll(/-/g, ''): defaultState.monthlyIncome;
    setIncome(value);
    setState({
      ...state,
      [e.target.name]: getIncomeValue(value, state.incomeFrequency),
    });
  };

  const getIncomeValue = (value, frequency) => {
    if (frequency === 'weekly') {
      value = getMonthlyValue(value);
    }
    return value || 0;
  };

  useEffect(() => {
    const { incomeFrequency, monthlyIncome } = store.getState().survey;

    if (incomeFrequency !== '') {
      if (incomeFrequency === 'weekly') {
        setIncome(getWeeklyValue(monthlyIncome));
      } else {
        setIncome(monthlyIncome);
      }
    }

    trackView({
      page_url: ANALYTICS.page_url.survey,
      site_section: ANALYTICS.site_section,
      page_lenguage: ANALYTICS.page_language,
      page_type: ANALYTICS.page_type,
      page_customer_group: ANALYTICS.page_customer_group,
      page_security_level: ANALYTICS.page_security_level,
      page_name: ANALYTICS.page_name.survey,
      page_category: ANALYTICS.page_category.survey,
      page_subcategory: ANALYTICS.page_subcategory,
      raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v2,
      // funnel_name: ANALYTICS.funnel_name.financialFitnessScore,
      // funnel_step_name: ANALYTICS.funnel_step_name.nameAndAge,
    });
  }, []);

  useEffect(() => {
    let isNextDisabled = state.monthlyIncome === '' || state.monthlyIncome === 0 || state.incomeFrequency === '';
    setNextDisabled(isNextDisabled);
    }, [state.monthlyIncome, state.incomeFrequency]);

  return (
    <Step
      data={{ ...state }}
      stepId={STEP_ID}
      prevStep={PREV_STEP}
      nextDisabled={nextDisabled}
      tms={{
        page_url: ANALYTICS.page_url.survey,
        site_section: ANALYTICS.site_section,
        page_lenguage: ANALYTICS.page_language,
        page_type: ANALYTICS.page_type,
        page_customer_group: ANALYTICS.page_customer_group,
        page_security_level: ANALYTICS.page_security_level,
        page_name: ANALYTICS.page_name.survey,
        page_category: ANALYTICS.page_category.survey,
        page_subcategory: ANALYTICS.page_subcategory,
        raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v3,
        form_field_1: 'Step: '+ STEP_ID +'/12',
        event_category: ANALYTICS.event_category.content,
        event_details: ANALYTICS.event_action.detail,
        event_action: ANALYTICS.event_action.button,
    }}
    >
      <div className="step__question">
        <div className="step__flex">
          <p className="t-survey step__flex--title">{t('steps.step5.line1')}</p>
          <p className="step__flex--subtitle">{t('steps.step5.line2')}</p>
        </div>
        <div className="step__flex">
          <p className="t-survey mt-xs">
            <Input
              title="Monthly Income"
              type="currency"
              placeholder={t('steps.step5.placeholder')}
              size={8}
              name="monthlyIncome"
              min="0"
              step=".01"
              // pattern = "/^[0-9.,]*$/"
              value={income}
              onChange={updateStepData}
              dataCy="input-monthly-income"
              border
            />
          </p>
        </div>
      </div>
      <div className="step__tip" dangerouslySetInnerHTML={{ __html: t('steps.step5.tip') }} />
    </Step>
  );
};

export default withTranslation('translations')(Step5);
