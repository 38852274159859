import React from 'react';
import PropTypes from 'prop-types';
import HeaderImage from 'components/HeaderImage/HeaderImage';
import HeaderNotAbleResults from 'components/HeaderNotAbleResults/HeaderNotAbleResults';

const NotAbleResults = ({ t }) => {
  return (
    <section className="results main">
      <HeaderImage notAble />
      <HeaderNotAbleResults t={t} />
    </section>
  );
};

NotAbleResults.propTypes = {
  t: PropTypes.func.isRequired,
};

export default NotAbleResults;
