import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { trackLink } from 'services/tms';
import { ANALYTICS } from 'constants/analytics';
// import { formatNumberWith2Decimals } from 'utils/util';

const ResilienceBlock = ({ t, rating, icon, name, top, dataBlock, showHeaderLabel }) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const showMoreText = isShowMore ? 'Show less' : 'Show more';
  const iconRating = rating === 'bad' ? 'warning' : 'check';

  const trackCta = ({ resilienceName, ctaText }) =>
    trackLink({
      page_url: ANALYTICS.page_url.result,
      event_category: ANALYTICS.event_category.content,
      event_action: ANALYTICS.event_action.button,
      event_content: `what your score says about you:${resilienceName.toLowerCase()}:${ctaText}`,
      page_name: ANALYTICS.page_name.result,
      page_subcategory: ANALYTICS.page_subcategory.result,
      raw_datalayer: ANALYTICS.raw_datalayer.v26,
      funnel_step_name: ANALYTICS.funnel_step_name.result,
      tool_event: ANALYTICS.tool_event.completed,
    });

  return (
    <div className={`resilience-block resilience-block--${rating}`}>
      <div className="resilience-block__header rb-header">
        <span className="rb-header__icon">
          <i aria-hidden="true" className={`icon icon-${icon}`}></i>
        </span>
        <h3 className="rb-header__title">{name}</h3>
        {showHeaderLabel && (
          <span className={`rb-header__label rb-header__label--${rating}`}>
            <i aria-hidden="true" className={`icon icon-${iconRating}`}></i>
            {t(`results.resilience.ratings.${rating}`)}
          </span>
        )}
      </div>
      {/* {dataBlock &&
          dataBlock.map(({ label, value, percent, valueBlockLabel }, index) => (
            <div key={index} className="resilience-block__data">
              <span className="t-h6 resilience-block__label">{label}</span>
              <div className="resilience-block__data-info">
                <div className="resilience-block__data-wrapper">
                  <div className="resilience-block__data-block">
                    <span className="resilience-block__value">£{formatNumberWith2Decimals(value)}</span>
                    <span className="resilience-block__label">{valueBlockLabel || t('results.resilience.data.month')}</span>
                  </div>
                  {percent >= 0 && (
                    <div className="resilience-block__data-block">
                      <span className="resilience-block__value">{percent}%</span>
                      <span className="resilience-block__label">&nbsp;{t('results.resilience.data.increment')}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))} */}
      <div className="resilience-block__tip rb-tip">
        <p className="rb-tip__title">{top.title}</p>
        <p className="rb-tip__title mt-s">{top.text2}</p>
      </div>
      <p className="resilience-block__tip--show" onClick={() => setIsShowMore(!isShowMore)}>
        {showMoreText}
      </p>
      {isShowMore && (
        <div className="resilience-block__action rb-tip">
          <div className="rb-tip__text mb-m">{top.text}</div>
        </div>
      )}
      <div className="rb-tip__link-wrapper">
        <p className="rb-tip__link">
          <a
            aria-label={top.cta + ' Opens in a new window'}
            href={top.link}
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => trackCta({ resilienceName: name, ctaText: top.cta })}
          >
            {top.cta}
          </a>
        </p>
      </div>
    </div>
  );
};

ResilienceBlock.defaultProps = {
  rating: 'excellent',
  icon: '',
  name: '',
  top: {
    title: '',
    text: '',
  },
  showHeaderLabel: true,
};

ResilienceBlock.propTypes = {
  rating: PropTypes.oneOf(['excellent', 'good', 'bad']).isRequired,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  top: PropTypes.object.isRequired,
  dataBlock: PropTypes.array.isRequired,
  showHeaderLabel: PropTypes.bool,
};

export default withTranslation('translations')(ResilienceBlock);
