import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';

import PropTypes from 'prop-types';
import store from '../../store';
import { setStep, setStepsCompleted, updateSurvey } from '../../store/actions';
import ScoreContext from '../../components/ScoreContext/ScoreContext';
import { trackLink } from 'services/tms';
import { ANALYTICS } from 'constants/analytics';
import { PDF } from '../../constants/pdf';

import ScorePdf from '../Pdf/ScorePdf';

const mapDispatchToProps = (dispatch) => ({
  updateSurvey: (survey) => dispatch(updateSurvey(survey)),
  setStepsCompleted: (isCompleted) => dispatch(setStepsCompleted(isCompleted)),
  setStep: (step) => dispatch(setStep(step)),
});

class Scoring extends Component {
  goToSurvey = () => {
    trackLink({
      page_url: ANALYTICS.page_url.result,
      event_category: ANALYTICS.event_category.content,
      event_action: ANALYTICS.event_action.onSite,
      event_content: 'edit my response',
      page_name: ANALYTICS.page_name.result,
      page_subcategory: ANALYTICS.page_subcategory.result,
      raw_datalayer: ANALYTICS.raw_datalayer.v25,
      funnel_name: ANALYTICS.funnel_name.financialFitnessScore,
      funnel_step_name: ANALYTICS.funnel_step_name.result,
    });
    this.props.setStep(1);
    this.props.history.push('/survey');
  };

  render() {
    const { t, type, average, scoring } = this.props;
    const { total: score } = scoring;
    const { survey } = store.getState();
    const { name } = JSON.parse(localStorage.getItem('storeState')).survey || survey;

    return (
      <div className="scoring">
        <div className="scoring__wrapper wrapper-small">
          <div className="scoring__wrapper--header">
            <div className="scoring__text">
              <h1>{t(`results.scoring.${type}`, { name })}</h1>
            </div>
            <div className="scoring__score score">
              <p>{t('results.scoring.number_top')}</p>
              <p className="score__number">{score || 0}</p>
              <p className="mb-s mt-s">{t('results.scoring.number_bottom')}</p>
            </div>
          </div>
          <div className="score-context__container">
            <ScoreContext score={score} average={average} />
            <div >
              <div className="score__info">
                <PDFDownloadLink
                  className="scoring__info--button score__info-download-link mt-xs"
                  fileName={PDF.name}
                  document={<ScorePdf scoring={scoring} survey={survey} />}
                >
                  {t('results.surveyexperience.download')}
                  <i className="icon icon-download" aria-hidden="true" />
                </PDFDownloadLink>
                <button
                  className="scoring__info--button scoring__edit mt-xs"
                  aria-label={t('results.scoring.edit')}
                  onClick={this.goToSurvey}
                >
                  {t('results.scoring.edit')}
                </button>
              </div>
              <div className="score__info mt-s" dangerouslySetInnerHTML={{ __html: t('results.scoring.linkHowTo') }} />
              {/* <a className="score__info" href='/howto' rel='noopener noreferrer' target='__blank'>
                {t('results.scoring.linkHowTo2')}
                <i className='icon icon-new-window' />
              </a> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Scoring.defaultProps = {
  type: 'excellent',
  average: 0,
  scoring: {},
};

Scoring.propTypes = {
  type: PropTypes.string,
  average: PropTypes.number,
  scoring: PropTypes.object,
};

const ConnectedScoring = connect(null, mapDispatchToProps)(Scoring);
export default withRouter(withTranslation('translations')(ConnectedScoring));
