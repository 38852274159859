import { Image, StyleSheet } from '@react-pdf/renderer';
import React from 'react';
import { getIcon } from 'utils/pdf';
import PropTypes from 'prop-types';

const IconPdf = ({ icon, size = 'normal', debug = false, marginLeft = 2, ...rest }) => {
  const iconStyle = StyleSheet.create({
    item: {
      marginLeft: marginLeft,
      objectFit: 'contain',
      ...rest,
      small: {
        height: 8,
        width: 8,
      },
      normal: {
        height: 16,
        width: 16,
      },
      big: {
        height: 24,
        width: 24,
      },
    },
  });

  return <Image debug={debug} src={getIcon(icon)} style={[iconStyle.item, iconStyle.item[size]]} />;
};

IconPdf.propTypes = {
  size: PropTypes.oneOf(['small', 'normal', 'big']),
};
export default IconPdf;
