import React from 'react';
import { useTranslation } from 'react-i18next';
import { StepperContext } from 'react-material-stepper';
import store from '../store';
import i18n from '../i18n';

export function useStepData(stepId) {
  const stepsCompleted = store.getState().stepsCompleted;
  // If we already went through this step, get the data from this step
  // If not, the state will initialize with the default empty state
  const { getData } = React.useContext(StepperContext);
  let stepData = getData(stepId);
  if (typeof stepData === 'undefined' && stepsCompleted) {
    stepData = store.getState().survey;
  } 

  if(JSON.parse(localStorage.getItem('modalExit'))){
    stepData = store.getState().survey;
  }
  return stepData;
}

export function useTranslations() {
  return useTranslation('translations', { i18n });
}
