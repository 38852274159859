import React from 'react';
import cx from 'classnames';
import BackgroundImage from '../../assets/images/results/header-scoring.webp';

const HeaderImage = ({ notAble }) => {
  return (
    <div
      className={cx('header-results', {
        'header-results--not-able': notAble,
      })}
    >
      <img className={'header-results__bg '} src={BackgroundImage} alt="" />
      <div className="header-gradient"></div>
    </div>
  );
};

export default HeaderImage;
