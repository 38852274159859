import React from 'react';

import Input from 'components/Input/Input';

const CustomDebtCard = ({ need, index, t, updateDebts }) => {
  return (
    <div className="step__essential-item step__essential-item--debts">
      <div className="step__essential-block mb-xs">
        <i aria-hidden="true" className={'step__essential-icon step__essential-icon--' + need[0]}></i>
        <p className="step__esential-title step__esential-title--debts">{t(`steps.step7.categories.${need[0]}.select`)}</p>
      </div>
      <div className="step__debts">
        <div className="s-table__input">
          <fieldset>
            <legend className="visually-hidden">{t(`steps.step7.categories.${need[0]}.select`)}</legend>
            <label htmlFor={t(`steps.step7.categories.${need[0]}.select`) + ' total'} className="step-label">
              {t('steps.step7.total_balance')}
            </label>
            <Input
              type="currency"
              id={t(`steps.step7.categories.${need[0]}.select`) + ' total'}
              size={6}
              font="t-h5"
              min="0"
              step=".01"
              value={need[1].total}
              placeholder="0"
              onChange={(e) => updateDebts(need[0], e.target.value, 'total')}
            />
          </fieldset>
        </div>
        <div className="s-table__input">
          <fieldset>
            <legend className="visually-hidden">{t(`steps.step7.categories.${need[0]}.select`)}</legend>
            <label htmlFor={t(`steps.step7.categories.${need[0]}.select`) + ' monthly'} className="step-label">
              {t('steps.step7.monthly_payment')}
            </label>
            <Input
              type="currency"
              id={t(`steps.step7.categories.${need[0]}.select`) + ' monthly'}
              size={6}
              font="t-h5"
              min="0"
              step=".01"
              value={need[1].month}
              placeholder="0"
              onChange={(e) => updateDebts(need[0], e.target.value, 'month')}
            />
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default CustomDebtCard;
