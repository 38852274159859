import React from 'react';

const StepProgressBar = ({ calculateProgress }) => {
  return (
    <div className="step-progress-bar">
      <div className="step-progress-bar__track" style={{ transform: `scaleX(${calculateProgress})` }} />
    </div>
  );
};

export default StepProgressBar;
