import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { ReactComponent as Check } from 'assets/images/check.svg';

export default class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputSize: this.props.size,
      style: {
        width: this.props.size + 'ex',
      },
    };
  }

  calculateWidth(value) {
    let str = value ?? this.props.placeholder;

    if (str === '') {
      str = this.props.placeholder;
    }

    str &&
      this.setState({
        inputSize: str.length,
      });
  }

  componentDidMount() {
    this.calculateWidth(this.props.value);
  }

  render() {
    const { type, currency, font, showError, border, errorMessage, inputRef, label, dataCy, ...attrs } = this.props;

    const inputWrapperType = border ? 'input__wrapper--border' : 'input__wrapper--no-border';
    const borderError = showError ? 'input__border-error' : null;

    let inputElem = (
      <input
        type={type}
        {...attrs}
        size={this.state.inputSize}
        onKeyUp={(e) => {
          this.calculateWidth(e.target.value);
        }}
        ref={inputRef}
        data-cy={dataCy}
      />
    );

    if (type === 'number') {
      inputElem = (
        <React.Fragment>
          <input className={borderError} type={type} {...attrs} style={this.state.style} ref={inputRef} data-cy={dataCy} />
          <label htmlFor={attrs.title} className="visually-hidden">
            {attrs.title}
          </label>
        </React.Fragment>
      );
    }

    if (type === 'currency') {
      inputElem = (
        <label htmlFor={attrs.title} className="input__currency">
          {currency} <input type="number" {...attrs} style={this.state.style} ref={inputRef} data-cy={dataCy} />
        </label>
      );
    }

    if (type === 'checkbox') {
      inputElem = (
        <label htmlFor={attrs.title} className="input__checkbox">
          <span className="input__checkbox--label">{label}</span>
          <span className="input__checkbox--input">
            <input type="checkbox" {...attrs} ref={inputRef} data-cy={dataCy} />
            <span className="input__checkbox--control">
              <Check />
            </span>
          </span>
        </label>
      );
    }

    return (
      <span className={`input ${font} ${attrs.disabled ? 'input--disabled' : ''} ${showError ? 'input--error' : ''}`}>
        <label htmlFor={attrs.title} className={inputWrapperType}>
          <span className="visually-hidden">{attrs.title}</span>
          {inputElem}
        </label>
      </span>
    );
  }
}

Input.defaultProps = {
  type: 'text',
  currency: '£',
  size: 8,
  font: 't-survey',
  showError: false,
  errorMessage: '',
  inputRef: null,
  label: '',
};

Input.propTypes = {
  type: PropTypes.string,
  currency: PropTypes.oneOf(['£', '€']),
  size: PropTypes.number,
  font: PropTypes.string,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
};
