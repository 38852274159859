import { StyleSheet, View } from '@react-pdf/renderer';
import { PDF } from 'constants/pdf';
import { useTranslations } from 'hooks';
import React, { useState } from 'react';
import { formatNumberWith2Decimals } from 'utils/util';
import IconPdf from './IconPdf';
import TextPdf from './TextPdf';

const EssentialsSpendPdf = ({ spendBasicNeeds }) => {
  const [monthlySpend] = useState(Object.entries(spendBasicNeeds).reduce((acc, current) => (acc += +current[1].value), 0));
  const { t } = useTranslations();

  return (
    <View style={essentialsSpend.item}>
      <TextPdf fontSize={15} color={PDF.color.black} fontWeight="thin">
        {t('pdf.essentialsSpend.title_1')}&nbsp;
        <TextPdf fontWeight="medium" color={PDF.color.black} fontSize={15}>
          £{formatNumberWith2Decimals(monthlySpend)}
        </TextPdf>{' '}
        {t('pdf.essentialsSpend.title_2')}
      </TextPdf>
      <View style={essentialsSpend.blocks}>
        {Object.entries(spendBasicNeeds).map(([need, ...spend], index) => (
          <View style={essentialsSpend.blocks.block} key={index}>
            <View style={essentialsSpend.blocks.block.spend}>
              <View style={essentialsSpend.blocks.block.name}>
                <IconPdf icon={t(`pdf.essentialsSpend.${need}.icon`)} marginRight={5} />
                <TextPdf color={PDF.color.black} lineHeight={1.2} fontWeight="thin">
                  {t(`pdf.essentialsSpend.${need}.name`)}
                </TextPdf>
              </View>
              <TextPdf fontSize={10} lineHeight={1.2} fontWeight="thin">
                {t(`pdf.essentialsSpend.${need}.help`)}
              </TextPdf>
            </View>
            <View style={essentialsSpend.blocks.block.value}>
              <TextPdf lineHeight={1.2} color={PDF.color.black} fontWeight="thin">
                £{formatNumberWith2Decimals(spend[0].value) || 0}
              </TextPdf>
              {spend[0].value !== '' && (
                <TextPdf lineHeight={1.2} fontSize={8} fontWeight="thin" alignSelf="flex-end">
                  {t(`pdf.${spend[0].frequency}`)}
                </TextPdf>
              )}
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

export default EssentialsSpendPdf;

const essentialsSpend = StyleSheet.create({
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  blocks: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    block: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginVertical: 8,
      name: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 2,
      },
      spend: {
        display: 'flex',
        flexDirection: 'column',
      },
      value: {
        display: 'flex',
        flexDirection: 'row',
      },
    },
  },
});
