import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { useStepData } from 'hooks';
import store from '../../../store';
import { trackView } from '../../../services/tms';
import { ANALYTICS } from '../../../constants/analytics';

import Step from '../Step';
import CardCustomInput from 'components/CardCustomInput/CardCustomInput';

const STEP_ID = '2';
const PREV_STEP = '1';

const Step2 = ({ t }) => {
  const stepData = useStepData(STEP_ID);
  const [applicant, setApplicant] = useState(stepData?.applicant || '');
  const [nextDisabled, setNextDisabled] = useState(true);
  const options = [
    { key: 'myself', value: t('steps.step2.option1') },
    { key: 'household', value: t('steps.step2.option2') },
  ];

  const getSelectLabelledBy = () => `${t('steps.step2.line1').replace(/\s/g, '')} custom-select-step-${store.getState().step}`;

  const selectProps = {
    option: applicant || '',
    optionsList: options,
    labelledBy: [getSelectLabelledBy()],
  };

  useEffect(() => {
    const isNextDisabled = applicant === '';
    setNextDisabled(isNextDisabled);
  }, [applicant]);

  useEffect(() => {
    // const { age } = store.getState().survey;
    trackView({
      page_url: ANALYTICS.page_url.survey,
      site_section: ANALYTICS.site_section,
      page_lenguage: ANALYTICS.page_language,
      page_type: ANALYTICS.page_type,
      page_customer_group: ANALYTICS.page_customer_group,
      page_security_level: ANALYTICS.page_security_level,
      page_name: ANALYTICS.page_name.survey,
      page_category: ANALYTICS.page_category.survey,
      page_subcategory: ANALYTICS.page_subcategory,
      raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v2,
      // funnel_name: ANALYTICS.funnel_name.financialFitnessScore,
      // funnel_step_name: ANALYTICS.funnel_step_name.nameAndAge,
    });
  }, []);

  const updateStepData = (value) => {
    setApplicant(value);
  };

  const getStepMessage = () => {
    if (applicant !== '') {
      return <div className="step__message" dangerouslySetInnerHTML={{ __html: t(`steps.step2.messages.great`) }}></div>;
    }
  };

  return (
    <Step
      data={{ applicant }}
      stepId={STEP_ID}
      prevStep={PREV_STEP}
      nextDisabled={nextDisabled}
      tms={{
        page_url: ANALYTICS.page_url.survey,
        site_section: ANALYTICS.site_section,
        page_lenguage: ANALYTICS.page_language,
        page_type: ANALYTICS.page_type,
        page_customer_group: ANALYTICS.page_customer_group,
        page_security_level: ANALYTICS.page_security_level,
        page_name: ANALYTICS.page_name.survey,
        page_category: ANALYTICS.page_category.survey,
        page_subcategory: ANALYTICS.page_subcategory,
        raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v3,
        form_field_1: 'Step: '+ STEP_ID +'/12',
        event_category: ANALYTICS.event_category.content,
        event_details: ANALYTICS.event_action.detail,
        event_action: ANALYTICS.event_action.button,
        // event_content: nextDisabled ? 'you must be over 16 years old to continue' : 'Next',
        // funnel_name: ANALYTICS.funnel_name.financialFitnessScore,
        // funnel_step_name: ANALYTICS.funnel_step_name.nameAndAge,
        // error_type: nextDisabled ? ANALYTICS.error_type.fieldValidation : 'None',
    }}
    >
      <fieldset className="step__question">
        <div className="step__flex">
          <legend className="t-survey step__flex--title">{t('steps.step2.line1')}</legend>
        </div>
        <CardCustomInput
          {...selectProps}
          selectState={applicant}
          onSelectChange={updateStepData}
          dataCy="select-yourself"
          step={store.getState().step}
        />
      </fieldset>
      {getStepMessage()}
    </Step>
  );
};

export default withTranslation('translations')(Step2);
