import { StyleSheet, View } from '@react-pdf/renderer';
// import BalanceImg from 'assets/images/results/header-scoring.webp';
import { useTranslations } from 'hooks';
import React from 'react';
import ProgressBarPdf from './ProgressBarPdf';
import TextPdf from './TextPdf';
import TitlePdf from './TitlePdf';

const BalancePdf = ({ essentials = 0, wants = 0, savings = 0 }) => {
  const { t } = useTranslations();

  return (
    <View style={balance.item} break>
      <View style={balance.content}>
        <TitlePdf>{t('results.balance.title')}</TitlePdf>
        <TextPdf marginBottom={10}>{t('results.balance.description')}</TextPdf>
        <TextPdf marginBottom={10}>{t('results.balance.body')}</TextPdf>
      </View>
      <View style={[balance.content, balance.content.progressBar]}>
        <ProgressBarPdf essentials={essentials} wants={wants} savings={savings} showLabels={false} />
        <ProgressBarPdf type="secondary" essentials={50} wants={30} savings={20} />
      </View>
      {/* <Image style={balance.image} source={BalanceImg}></Image> */}
    </View>
  );
};

export default BalancePdf;

const balance = StyleSheet.create({
  item: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
  },
  content: {
    width: '50%',
    marginRight: 20,
    progressBar: {
      marginTop: 20
    }
  },
  image: {
    width: '50%',
    objectFit: 'cover',
    maxWidth: '100%',
  },
});
