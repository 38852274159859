import React from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';

const CustomRadio = ({
  id,
  optionsList,
  step,
  type,
  labelledBy,
  big,
  small,
  dataCy,
  setValueWeekly,
  need,
}) => {
  const idSelect = 'custom-select-step-' + step;

  const setSelectClass = (big, small) =>
    cx(
      'custom-radio-container',
      { 'custom-radio-container--big': big },
      { 'custom-radio-container--small': small },
      { 'custom-radio-container--normal': !small && !big }
    );

  const onChangeSelect = (e) => {
    if (e.target.value === 'Weekly') {
      setValueWeekly(true);
    } else {
      setValueWeekly(false);
    }
  };

  return (
    <div className={setSelectClass(big, small)}>
      <label htmlFor={idSelect} className="visually-hidden"></label>
      {optionsList.map((option, index) => {
        return (
          <div className="custom-radio-container__radio" key={index}>
            <input
              type={type}
              value={option.value}
              id={id + index}
              name={id}
              defaultChecked={need[1].frequency === option.key}
              onClick={onChangeSelect}
              aria-labelledby={labelledBy}
              data-cy={dataCy}
            />
            <label htmlFor={id + index}>{option.value}</label>
          </div>
        );
      })}
    </div>
  );
};

CustomRadio.defaultProps = {
  id: '',
  optionsList: [],
  step: 0,
  type: 'radio',
  labelledBy: [],
  big: false,
  small: false,
  setSelected: undefined,
};

CustomRadio.propTypes = {
  id: PropTypes.string,
  optionsList: PropTypes.array,
  step: PropTypes.number,
  type: PropTypes.string,
  labelledBy: PropTypes.array,
  big: PropTypes.bool,
  small: PropTypes.bool,
  setSelected: PropTypes.func,
};

export default CustomRadio;
