import React from 'react';
import PropTypes from 'prop-types';

const ResultsBadCard = ({ t, title, description, label, icon, link }) => {
  return (
    <div className="results-bad-card">
      <div className="results-bad-card__title">
        <h2>{t(title)}</h2>
      </div>
      <div className="results-bad-card__description">
        <p>{t(description)}</p>
      </div>
      {label && !icon && (
        <div className="results-bad-card__label">
          <a href={t(link)}>{t(label)}</a>
        </div>
      )}
      {label && icon && (
        <div className="results-bad-card__label">
          <p>
            <a href={t(link)}>
              {t(label)}
              <i aria-hidden="true" className={t(icon)}></i>
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

ResultsBadCard.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  label: PropTypes.string,
  icon: PropTypes.string,
  link: PropTypes.string,
};

export default ResultsBadCard;
