import React from 'react';

import PropTypes from 'prop-types';

import AdditionalResources from 'components/AdditionalResources/AdditionalResources';
import Balance from 'components/Balance/Balance';
import HeaderImage from 'components/HeaderImage/HeaderImage';
import Resilience from 'components/Resilience/Resilience';
// import Saving from 'components/Saving/Saving';
import Scoring from 'components/Scoring/Scoring';
import Tips from 'components/Tips/Tips';

const AbleResults = ({ t, messagesType, scoring, insurances, payingPension }) => {
  return (
    <section className="results main">
      <h1 className="visually-hidden">{t('results.h1')}</h1>
      <HeaderImage />
      <Scoring type={messagesType} average={scoring.average} scoring={scoring} />
      <Balance essential={scoring.essentialPercent} wants={scoring.wantsPercent} savings={scoring.savingPercent} />
      <div className="wrapper-small">
        <Resilience scoring={scoring} insurances={insurances} payingPension={payingPension} />
        {/* <Saving
          totalSavings={scoring.totalSavings}
          savings={scoring.saving}
          savingPercent={scoring.savingPercent}
          savingPercentNotRound={scoring.savingPercentNotRound}
          monthlyIncome={scoring.monthlyIncome}
          emergencyFundMonths={scoring.C6.value}
        /> */}
      </div>
      <Tips  t={t}/>
      <AdditionalResources t={t} scoring={scoring} />
    </section>
  );
};

AbleResults.propTypes = {
  insurances: PropTypes.object.isRequired,
  messagesType: PropTypes.string.isRequired,
  payingPension: PropTypes.string.isRequired,
  scoring: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default AbleResults;
