import { StyleSheet, Text } from '@react-pdf/renderer';
import { PDF } from 'constants/pdf';
import React from 'react';

const TextPdf = ({
  children,
  fontSize = PDF.font.default.text.fontSize,
  color = PDF.color.grey,
  fontWeight = PDF.font.default.text.fontWeight,
  lineHeight = PDF.font.lineHeight,
  debug = false,
  ...rest
}) => {
  const textStyle = StyleSheet.create({
    fontSize: fontSize,
    lineHeight: lineHeight,
    color: color,
    fontWeight: fontWeight,
    ...rest,
  });

  return (
    <Text debug={debug} style={textStyle}>
      {children}
    </Text>
  );
};

export default TextPdf;
