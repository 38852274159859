import React, { useEffect } from 'react';
import { useState } from 'react';

import CustomRadio from 'components/CustomRadio/CustomRadio';
import Input from 'components/Input/Input';
import { WEEKLY_FACTOR } from 'constants/survey';

const CustomMonthlyCard = ({ t, need, index, onSelectChange }) => {
  const [selected, setSelected] = useState(false);
  const [isValueWeekly, setIsValueWeekly] = useState(need[1].frequency === 'weekly' ? true : false);
  const [valueInput, setValueInput] = useState(need[1].valuePrev ? need[1].valuePrev : '');
  const [newValue, setNewValue] = useState(0);
  const [preValueWeekly, setpreValueWeekly] = useState(isValueWeekly);

  const classSelected = need[1].selected && need[1].value !== '' && need[1].value !== '0' ? 'step__essential-block__selected' : 'step__essential-block__not-selected';

  const options = [
    { key: 'monthly', value: t('steps.step6.option1') },
    { key: 'weekly', value: t('steps.step6.option2') },
  ];

  const getSelectLabelledBy = () =>
    `${t('steps.step6.line1').replace(/\s/g, '')}
  custom-select-step-${1}`;

  const selectProps = {
    defaultText: { key: '', value: t('steps.buttons.select') },
    optionsList: options,
    labelledBy: [getSelectLabelledBy()],
  };

  const onChangeValue = (e) => {
    setValueInput(e.target.value);
    setSelected(true)
    setpreValueWeekly(isValueWeekly)
  };
  
  useEffect(() => {
    if(preValueWeekly !== isValueWeekly && valueInput !== '') {
      setSelected(true)
    }
    selected && setNewValue(isValueWeekly ? parseFloat(valueInput !== '' ? valueInput : '0') * WEEKLY_FACTOR : valueInput);
    selected && onSelectChange({
      [need[0]]: {
        frequency: isValueWeekly ? 'weekly' : 'monthly',
        value: newValue.toString(),
        valuePrev: valueInput ? valueInput : '0',
        selected: selected
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValueWeekly, valueInput, newValue, selected]);

  return (
    <div className="step__essential-item" key={index}>
      <div className={classSelected}>
        <div className="step__essential-block-items">
          <div className="step__essential-block-item">
            <div className="step__essential-block-item-title">
              <p className="step__esential-title step__esential-title--spends">{t(`steps.step6.categories.${need[0]}.select`)}</p>
            </div>
            <p className="t-help">{t(`steps.step6.categories.${need[0]}.help`)}</p>
          </div>
        </div>
        <div className="step__essential-block-spend">
          <div className="s-table__input">
            <label className="visually-hidden" htmlFor={t(`steps.step6.categories.${need[0]}.id`)}>
              {t(`steps.step6.categories.${need[0]}.select`)}
            </label>
            <Input
              name={t(`steps.step6.categories.${need[0]}.id`)}
              id={t(`steps.step6.categories.${need[0]}.id`)}
              type="currency"
              size={4}
              font="t-h3"
              min="0"
              border={false}
              step=".06"
              value={valueInput}
              placeholder="0"
              onChange={onChangeValue}
              dataCy={t(`steps.step6.categories.${need[0]}.id`)}
            />
          </div>
          <div className="s-table__input">
            <CustomRadio
              {...selectProps}
              id={t(`steps.step6.categories.${need[0]}.select`)}
              need={need}
              type="radio"
              setSelected={setSelected}
              selected={selected}
              labelledBy={selectProps.labelledBy}
              small
              dataCy={`input${t(`steps.step6.categories.${need[0]}.select`)}`}
              setValueWeekly={setIsValueWeekly}
              value={need[1].valuePrev}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomMonthlyCard;
