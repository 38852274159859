import React from 'react';

import PropTypes from 'prop-types';

export const Button = (props) => {
  let buttonClass = ['button'];

  if (props.secondary) {
    buttonClass.push('button--secondary');
  } else if (props.link) {
    buttonClass.push('button--link');
  } else {
    buttonClass.push('button--primary');
  }

  if (props.size === 'small') {
    buttonClass.push('button--small');
  } else if (props.size === 'big') {
    buttonClass.push('button--big');
  }

  // buttonClass.push(props.secondary ? 'button--secondary' : 'button--primary');
  props.icon && buttonClass.push('button--icon-' + props.iconPosition);

  let icon = props.icon && (
    // <span className="button__icon">
    <i className={`icon icon-${props.icon}`}></i>
    // </span>
  );

  return (
    <button
      type="button"
      className={buttonClass.join(' ')}
      aria-label={`${props.text} survey page`}
      disabled={props.disabled}
      onClick={props.onClick}
      data-cy={props.dataCy}
    >
      {icon}
      <span className={`t-${props.secondary ? 'p' : 'button'}`}>{props.text}</span>
    </button>
  );
};

Button.defaultProps = {
  secondary: false,
  size: 'normal',
  link: false,
  iconPosition: 'left',
  disabled: false,
};

Button.propTypes = {
  secondary: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'normal', 'big']),
  link: PropTypes.bool,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  disabled: PropTypes.bool,
};
