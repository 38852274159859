import React from "react"

import { OTHER_TIPS } from "../../constants/results"
import isEmptyObject from "utils/isEmptyObject"

const Tips = ({ t }) => {
  const localTypeCategory = JSON.parse(localStorage.getItem("storeState"))

  let categoryWorry = localTypeCategory.survey.categoryWorry
  let financialGoal = localTypeCategory.survey.financialGoal
  let typeCategory = !isEmptyObject(categoryWorry)
    ? categoryWorry
    : financialGoal

  const asArray = Object.entries(typeCategory)
  const filtered = asArray.filter(([key, value]) => value === "yes")
  const justStrings = Object.fromEntries(filtered)
  const justStringsFormat = Object.keys(justStrings)


 OTHER_TIPS.filter((tip) => (
    justStringsFormat.map((value) => {
      tip.isSelected = ""
      if (value === tip.id) {
        tip.isSelected = 'yes'
      }
      return tip
    })
 ))
  let formattedTips= OTHER_TIPS.filter(value => value.isSelected === 'yes')

  return (
    <>
      {!isEmptyObject(typeCategory) && (
        <div className='wrapper-small tips'>
          <h2 className='tips__title'>{t("results.other_tips.title")}</h2>
          <div className='tips__item'>
            {formattedTips.map((resource) => (
              <div key={resource.id }className='tips__items'>
                <div className='tips__items--image'>
                  {resource.image && (
                    <img src={resource.image} alt={t(resource.title)} />
                  )}
                </div>
                <div className='tips__items--content'>
                  <div className='tips__items--content__title'>
                    <span>
                      <i
                        className='tip__icon icon icon-saving'
                        aria-hidden='true'
                      ></i>
                    </span>
                    <h3>{t(resource.title)}</h3>
                  </div>
                  <div className='tips__items--content__description'>
                    <p>{t(resource.description)}</p>
                  </div>
                </div>
                <a
                  className='tips__items--content__link'
                  href={t(resource.url)}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <p>
                    {t(resource.textLink)}
                    <i className='icon icon-new-window' aria-hidden='true'></i>
                  </p>
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default Tips
