import { Link, StyleSheet, View } from '@react-pdf/renderer';
import { PDF } from 'constants/pdf';
import { useTranslations } from 'hooks';
import React from 'react';
import IconPdf from './IconPdf';
import ScoreContextPdf from './ScoreContextPdf';
import TextPdf from './TextPdf';
import TitlePdf from './TitlePdf';

const ScoringPdf = ({ total, average, name, type }) => {
  const { t } = useTranslations();
  name = JSON.parse(localStorage.getItem('storeState')).survey.name || name

  return (
    <View style={styles.scoring}>
       <View style={styles.scoring.container}>
      <View style={styles.wrapper}> 
          <View style={styles.text}>
            <TitlePdf opacity={1} fontSize={30} color={PDF.color.white}>
              {t(`results.scoring.${type}`, { name })}
            </TitlePdf>
          </View>
          <View style={styles.score}>
            <TextPdf color={PDF.color.border_select}>{t('results.scoring.number_top')}</TextPdf>
            <TextPdf color={PDF.color.white} fontSize={50} paddingVertical={0}>
              {total}
            </TextPdf>
            <TextPdf color={PDF.color.border_select}>{t('results.scoring.number_bottom')}</TextPdf>
          </View>
      </View>
        <View style={[styles.wrapper, styles.wrapperInfo]}>
          <View style={[styles.text, styles.text.link]}>
            <Link style={styles.score.howTo} src={`${window.location.origin}/howTo`}>
              {t('results.scoring.info')}
           
            </Link>
            <IconPdf icon="new_window" size="small"/>
          </View>
          <View style={[styles.score, styles.score.range]}>
            <ScoreContextPdf scoring={total} average={average} />
          </View>
        </View>
        </View>
    </View>
  );
};

export default ScoringPdf;

const styles = StyleSheet.create({
  scoring: {
    maxHeight: '100%',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: PDF.color.white,
    top: 60,
    container: {
      alignItems: 'center',
      width: '85%',
      backgroundColor: PDF.color.black,
      opacity: 0.7,
    }
 
  },
  wrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingHorizontal: 20,
    paddingVertical: 20,
    width: '100%',
    paddingBottom: 0,
  },
  wrapperInfo: {
    alignItems: 'flex-start',
    
  },
  text: {
    flex: 1,
    marginRight: 10,
    link: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
    }
  },
  score: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
    width: 100,
    height: '100%',
    opacity: 1,
    paddingTop: 10,
    paddingBottom: 0,
    howTo: {
      fontSize: 10,
      fontWeight: 'medium',
      textDecoration: 'none',
      paddingTop: 20,
      marginRight: 5
    },
    range: {
      minWidth: '200px',
      with: 100,
    }
  },
});
