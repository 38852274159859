import axios from 'axios';

const { REACT_APP_API_USER: API_USER, REACT_APP_API_PASSWORD: API_PASSWORD } = process.env;

const login = () =>
  axios.post(
    '/api/v1/login',
    { user: API_USER, password: API_PASSWORD },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

export const saveScoring = async (data) => {
  const { token } = await login().then((response) => response.data);
  return await axios.post('/api/v1/survey/resilience', JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json',
      'X-API-Key': `Bearer ${token}`,
    },
  });
};
