import React from 'react';

import { RESOURCES_NOT_EXTRA_SAVINGS_MONTH, RESOURCES_EXTRA_SAVINGS_MONTH } from '../../constants/results';

const AdditionalResources = ({ t, scoring }) => {
  const emergencyFundMonths = scoring.C6.value;

  const typeResource = emergencyFundMonths >=3  ? RESOURCES_EXTRA_SAVINGS_MONTH : RESOURCES_NOT_EXTRA_SAVINGS_MONTH;

  return (
    <div className="wrapper-small resources">
      <h2 className="resources__title">{t('results.additionalResources.title')}</h2>

      <div className="resources__item">
        {typeResource.map((resource) => (
          <a key={resource.id} className="resources__items" href={resource.link} target="_blank" rel="noopener noreferrer">
            <div className="resources__items--image">
              <img src={resource.image} alt={t(resource.title)} />
            </div>
            <div className="resources__items--content">
              <div className="resources__items--content__title">
                <h3>{t(resource.title)}</h3>
                <span>
                  <i className="icon icon-arrow" aria-hidden="true"></i>
                </span>
              </div>
              <div className="resources__items--content__description">
                <p className="resources__items--content__description--item">{t(resource.description)}</p>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default AdditionalResources;
