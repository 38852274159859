import { Image, StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import logo from '../../assets/images/logo.png';

const HeaderTopPdf = () => {
  return (
    <View style={styles.top}>
      <Image style={styles.logo} source={logo} />
    </View>
  );
};

export default HeaderTopPdf;

const styles = StyleSheet.create({
  top: {
    height: 40,
    paddingHorizontal: 12,
    paddingVertical: 10,
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    logo: {
      objectFit: 'contain',
      height: 10,
    },
  },
});
