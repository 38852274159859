import { ANALYTICS } from 'constants/analytics';

const defaultData = {
  page_url: '',
  event_category: '',
  event_subcategory: '',
  event_action: '',
  event_content: '',
  site_section: ANALYTICS.site_section,
  page_language: ANALYTICS.page_language,
  page_type: ANALYTICS.page_type,
  page_customer_group: ANALYTICS.page_customer_group,
  page_business_line: ANALYTICS.page_business_line,
  page_security_level: '0',
  product_category: '',
  product_subcategory: '',
  product_id: '',
  page_name: '',
  page_category: ANALYTICS.page_category,
  page_subcategory: '',
  raw_datalayer: '',
  funnel_name: '',
  funnel_step: '',
  funnel_step_name: '',
  internal_reference_id: '',
  form_field_1: '',
  form_field_2: '',
  form_field_3: '',
  tool_name: ANALYTICS.tool_name,
  tool_type: '',
  tool_event: '',
  site_country: ANALYTICS.site_country,
  site_region: ANALYTICS.site_region,
  error_type: '',
};

const TMS = window.TMS || {};

// The source code only needs a very simple data object to be set because the relevant page data is going to be set later in a View Call:
window.utag_data = {
  site_type : "single_page"
};

export const trackView = (data) => {
  // console.log('dataView', data)
  TMS.hasOwnProperty('trackView') &&
    TMS.trackView({
      ...defaultData,
      ...data,
    });
};
export const trackLink = (data) => {
  // console.log('dataLink', data)
  TMS.hasOwnProperty('trackEvent') &&
    TMS.trackEvent('link', {
      ...defaultData,
      ...data,
    });
};
