import { EXCELLENT, GOOD, BAD } from './ratings';

export const BANDING = {
  C1: {
    bandings: [0.36, 0.5, 0.78, 0.78],
    value: [21, 16, 4, 2],
    rating: [EXCELLENT, GOOD, BAD, BAD],
  },
  C2: {
    bandings: [0.01, 0.26, 0.31, 0.31],
    value: [3, 4, 7, 15],
    rating: [BAD, BAD, GOOD, EXCELLENT],
  },
  C3: {
    bandings: ['always', 'sometimes', 'rarely'],
    value: [6, 3, 3],
    rating: [GOOD, BAD, BAD],
  },
  C4: {
    bandings: [0, 0.03, 0.07, 0.22, 0.31, 0.44, 0.44],
    value: [15, 11, 7, 5, 3, 2, 1],
    rating: [EXCELLENT, EXCELLENT, GOOD, GOOD, BAD, BAD, BAD],
  },
  C5: {
    bandings: [0, 500, 1000, 1500, 1500],
    value: [0, 4, 11, 15, 19],
    rating: [BAD, GOOD, GOOD, EXCELLENT, EXCELLENT],
  },
  C6: {
    bandings: [1, 2, 4, 6, 6],
    value: [1, 5, 8, 15, 24],
    rating: [BAD, BAD, BAD, GOOD, EXCELLENT],
  },
};
