import imageTip1 from '../assets/images/results/tips1.webp';
import imageTip2 from '../assets/images/results/tips2.webp';
import imageTip3 from '../assets/images/results/tips3.webp';
import imageResources1 from '../assets/images/results/additional-resources1.webp';
import imageResources2 from '../assets/images/results/additional-resources2.webp';
import imageResources3 from '../assets/images/results/additional-resources3.webp';

export const TIPS = [
  {
    id: 1,
    title: 'results.tips.item1.title',
    description: 'results.tips.item1.description',
    textLink: 'results.tips.item1.link',
    link: 'https://www.hsbc.co.uk/savings/everyday-spending-hacks/',
    image: imageTip1,
  },
  {
    id: 2,
    title: 'results.tips.item2.title',
    description: 'results.tips.item2.description',
    textLink: 'results.tips.item2.link',
    link: 'https://www.hsbc.co.uk/savings/how-to-save-money/',
    image: imageTip2,
  },
  {
    id: 3,
    title: 'results.tips.item3.title',
    description: 'results.tips.item3.description',
    textLink: 'results.tips.item3.link',
    link: 'https://www.hsbc.co.uk/investments/saving-vs-investing/',
    image: imageTip3,
  },
  // {
  //   id: 4,
  //   title: 'results.tips.item3.title',
  //   description: 'results.tips.item3.description',
  //   textLink: 'results.tips.item3.link',
  //   link: 'https://www.hsbc.co.uk/investments/saving-vs-investing/',
  //   image: imageTip3,
  // },
];

export const OTHER_TIPS = [
  {
    id: 'costOfLiving',
    title: 'results.other_tips.item1.title',
    description: 'results.other_tips.item1.description',
    url: 'results.other_tips.item1.url',
  },
  {
    id: 'energyBills',
    title: 'results.other_tips.item2.title',
    description: 'results.other_tips.item2.description',
    url: 'results.other_tips.item2.url',
  },
  {
    id: 'groceries',
    title: 'results.other_tips.item3.title',
    description: 'results.other_tips.item3.description',
    url: 'results.other_tips.item3.url',
  },
  {
    id: 'managingDebt',
    title: 'results.other_tips.item4.title',
    description: 'results.other_tips.item4.description',
    url: 'results.other_tips.item4.url',
  },
  {
    id: 'budgeting',
    title: 'results.other_tips.item5.title',
    description: 'results.other_tips.item5.description',
    url: 'results.other_tips.item5.url',
  },
  {
    id: 'financialAnxiett',
    title: 'results.other_tips.item6.title',
    description: 'results.other_tips.item6.description',
    url: 'results.other_tips.item6.url',
  },
  {
    id: 'budgetBetter',
    title: 'results.other_tips.item7.title',
    description: 'results.other_tips.item7.description',
    url: 'results.other_tips.item7.url',
  },
  {
    id: 'manageYourDebt',
    title: 'results.other_tips.item8.title',
    description: 'results.other_tips.item8.description',
    url: 'results.other_tips.item8.url',
  },
  {
    id: 'growYourMoney',
    title: 'results.other_tips.item9.title',
    description: 'results.other_tips.item9.description',
    url: 'results.other_tips.item9.url',
  },
  {
    id: 'somethingSpecial',
    title: 'results.other_tips.item10.title',
    description: 'results.other_tips.item10.description',
    url: 'results.other_tips.item10.url',
  },
  {
    id: 'startingInvest',
    title: 'results.other_tips.item11.title',
    description: 'results.other_tips.item11.description',
    url: 'results.other_tips.item11.url',
  },
  {
    id: 'savingRetirement',
    title: 'results.other_tips.item12.title',
    description: 'results.other_tips.item12.description',
    url: 'results.other_tips.item12.url',
  }
]
export const RESOURCES = [
  {
    id: 1,
    title: 'results.additionalResources.item3.title',
    description: 'results.additionalResources.item3.description',
    link: 'https://www.hsbc.co.uk/financial-fitness/fitness-score/#health-check',
    image: imageResources3,
  },
  {
    id: 2,
    title: 'results.additionalResources.item1.title',
    description: 'results.additionalResources.item1.description',
    link: 'https://www.hsbc.co.uk/financial-fitness/fitness-score/#register',
    image: imageResources1,
  },
];
// - Si el cliente tiene ahorrado una cantidad equivalente a 3 meses o más de ingresos,
// - Le enseñamos: the benefits calculator savings https://www.hsbc.co.uk/financial-fitness/could-you-be-missing-out-on-unclaimed-benefits/
// - Si no, le enseñamos el investment calculator - https://www.hsbc.co.uk/wealth/investment-calculator/
export const RESOURCES_NOT_EXTRA_SAVINGS_MONTH = [
  ...RESOURCES,
  {
    //benefits calculator
    id: 3,
    title: 'results.additionalResources.item2.title',
    description: 'results.additionalResources.item2.description',
    link: 'https://www.hsbc.co.uk/financial-fitness/could-you-be-missing-out-on-unclaimed-benefits/',
    image: imageResources2,
  },
];

export const RESOURCES_EXTRA_SAVINGS_MONTH = [
  ...RESOURCES,
  {
    //investment calculator
    id: 3,
    title: 'results.additionalResources.item4.title',
    description: 'results.additionalResources.item4.description',
    link: 'https://www.hsbc.co.uk/wealth/investment-calculator/',
    image: imageResources2,
  },
];

export const HEADER_NOT_RESULTS = [
  {
    id: 1,
    title: 'results.notAbleHeader.item1.title',
    description: 'results.notAbleHeader.item1.description',
    label: 'results.notAbleHeader.item1.label',
    icon: 'results.notAbleHeader.item1.icon',
    link: 'results.notAbleHeader.item1.link',
  },
  {
    id: 2,
    title: 'results.notAbleHeader.item2.title',
    description: 'results.notAbleHeader.item2.description',
    icon: 'results.notAbleHeader.item2.icon',
    link: 'results.notAbleHeader.item2.link',
  },
];

export const DEBTOVERDRAFT = [
  {
    id: 1,
    icon: 'results.debtOverdraft.item1.icon',
    title: 'results.debtOverdraft.item1.title',
    description: 'results.debtOverdraft.item1.description',
    label: 'results.debtOverdraft.item1.label',
    link: 'results.debtOverdraft.item1.link',
  },
  {
    id: 2,
    icon: 'results.debtOverdraft.item2.icon',
    title: 'results.debtOverdraft.item2.title',
    description: 'results.debtOverdraft.item2.description',
    label: 'results.debtOverdraft.item2.label',
    link: 'results.debtOverdraft.item2.link',
  },
  {
    id: 3,
    icon: 'results.debtOverdraft.item3.icon',
    title: 'results.debtOverdraft.item3.title',
    description: 'results.debtOverdraft.item3.description',
    label: 'results.debtOverdraft.item3.label',
    link: 'results.debtOverdraft.item3.link',
  },
  {
    id: 4,
    icon: 'results.debtOverdraft.item4.icon',
    title: 'results.debtOverdraft.item4.title',
    description: 'results.debtOverdraft.item4.description',
    label: 'results.debtOverdraft.item4.label',
    link: 'results.debtOverdraft.item4.link',
  },
  {
    id: 5,
    icon: 'results.debtOverdraft.item5.icon',
    title: 'results.debtOverdraft.item5.title',
    description: 'results.debtOverdraft.item5.description',
    label: 'results.debtOverdraft.item5.label',
    link: 'results.debtOverdraft.item5.link',
  },
  {
    id: 6,
    icon: 'results.debtOverdraft.item6.icon',
    title: 'results.debtOverdraft.item6.title',
    description: 'results.debtOverdraft.item6.description',
    label: 'results.debtOverdraft.item6.label',
    link: 'results.debtOverdraft.item6.link',
  },
];

export const MORTGAGEPAYMENT = [
  {
    id: 1,
    title: 'results.mortgagePayment.item1.title',
    description: 'results.mortgagePayment.item1.description',
    icon: 'results.mortgagePayment.item1.icon',
  },
  {
    id: 2,
    title: 'results.mortgagePayment.item2.title',
    description: 'results.mortgagePayment.item2.description',
    icon: 'results.mortgagePayment.item2.icon',
  },
  {
    id: 3,
    title: 'results.mortgagePayment.item3.title',
    description: 'results.mortgagePayment.item3.description',
    icon: 'results.mortgagePayment.item3.icon',
  },
];

export const DEDICATEDTEAM = [
  {
    id: 1,
    title: 'results.dedicatedTeam.item1.title',
    description: 'results.dedicatedTeam.item1.description',
    label: 'results.dedicatedTeam.item1.label',
    link: 'results.dedicatedTeam.item1.link',
  },
  {
    id: 2,
    title: 'results.dedicatedTeam.item2.title',
    description: 'results.dedicatedTeam.item2.description',
    label: 'results.dedicatedTeam.item2.label',
    link: 'results.dedicatedTeam.item2.link',
  },
];
