import { Link, StyleSheet, View } from '@react-pdf/renderer';
import { PDF } from 'constants/pdf';
import { useTranslations } from 'hooks';
import React from 'react';

const FooterPdf = () => {
  const { t } = useTranslations();

  return (
    <View style={footer.item}>
      <Link style={footer.link} src="https://www.hsbc.co.uk/privacy-notice/">
        {t('generic.privacy')}
      </Link>
      <Link style={footer.link} src="https://www.hsbc.co.uk/legal/">
        {t('generic.terms')}
      </Link>
      <Link style={footer.link} src="https://www.hsbc.co.uk/accessibility/">
        {t('generic.accesibility')}
      </Link>
    </View>
  );
};

export default FooterPdf;

const footer = StyleSheet.create({
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 12,
    backgroundColor: PDF.color.black,
    color: PDF.color.white,
    marginTop: 'auto',
    marginRight: 'auto',
    paddingHorizontal: 20,
    height: 40,
    width: '100%',
  },
  link: {
    marginRight: 12,
    textDecoration: 'none',
    fontWeight: 'medium',
  },
});
