import React from 'react';

import PropTypes from 'prop-types';

const InputCustom = ({ type, value, name, selectState, idSelect, setValueInput }) => {
  const onBorderChange = (e) => {
    setValueInput(e.target.value)
    if (type === 'radio') setValueInput(e.target.value);
  };

  return (
    <div className="custom-select-container-input">
         <input
          type={type}
          value={value}
          checked={type === 'checkbox' ?  selectState[value] === 'yes' : selectState === value}
          onChange={onBorderChange}
          name={name}
          id={idSelect}
          onClick={onBorderChange}
        />
      <span className="t-p">Select</span>
    </div>
  );
};

InputCustom.defaultProps = {
  type: 'radio',
  font: 't-survey',
};

InputCustom.propTypes = {
  font: PropTypes.string,
  type: PropTypes.string,
};

export default InputCustom;
