import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

class ProgressBar extends Component {
  constructor(props, context) {
    super(props);

    this.progressRef = React.createRef();
  }

  highlight(className) {
    const wrapper = this.progressRef.current;
    wrapper.classList.add('hovering');
    const elements = wrapper.getElementsByClassName(className);
    for (let elem of elements) {
      elem.classList.add('highlight');
    }
  }

  unhighlight() {
    const wrapper = this.progressRef.current;
    wrapper.classList.remove('hovering');
    const elements = [...wrapper.getElementsByClassName('highlight')];
    for (let elem of elements) {
      elem.classList.remove('highlight');
    }
  }

  render() {
    const { t, essential, savings, wants, type } = this.props;
    return (
      <div className="progress-bar">
        {type ? (
          <h3 className="t-h4 mb-s">{t('results.balance.ideal')}</h3>
        ) : (
          <h3 className="t-h4 mb-s mt-m">{t('results.balance.current')}</h3>
        )}
        <div className="progress-wrapper mb-l" ref={this.progressRef}>
          <div className={`progress-bar-row ${type ? 'secondary' : 'primary'}`}>
            <div
              className="progress-bar-level essentials"
              style={{ width: essential + '%' }}
              onMouseEnter={() => this.highlight('essentials')}
              onMouseLeave={() => this.unhighlight()}
            ></div>
            {wants !== 0 && (
              <div
                className="progress-bar-level wants"
                style={{ width: wants + '%' }}
                onMouseEnter={() => this.highlight('wants')}
                onMouseLeave={() => this.unhighlight()}
              ></div>
            )}
            {savings !== 0 && (
              <div
                className="progress-bar-level savings"
                style={{ width: savings + '%' }}
                onMouseEnter={() => this.highlight('savings')}
                onMouseLeave={() => this.unhighlight()}
              ></div>
            )}
          </div>
          <div className="progress-bar-row progress-text">
            <div
              className="progress-bar-text essentials"
              style={{ width: essential + '%' }}
              onMouseEnter={() => this.highlight('essentials')}
              onMouseLeave={() => this.unhighlight()}
            >
              <p className="t-h3">{essential}%</p>
              <p className="t-h5 t-gray">{t('results.balance.essential')}</p>
            </div>
            {wants !== 0 && (
              <div
                className="progress-bar-text wants"
                style={{ width: wants + '%' }}
                onMouseEnter={() => this.highlight('wants')}
                onMouseLeave={() => this.unhighlight()}
              >
                <p className="t-h3">{wants}%</p>
                <p className="t-h5 t-gray">{t('results.balance.wants')}</p>
              </div>
            )}
            {savings !== 0 && (
              <div
                className="progress-bar-text savings"
                style={{ width: savings + '%' }}
                onMouseEnter={() => this.highlight('savings')}
                onMouseLeave={() => this.unhighlight()}
              >
                <p className="t-h3">{savings}%</p>
                <p className="t-h5 t-gray">{t('results.balance.savings')}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ProgressBar.defaultProps = {
  essential: 0,
  wants: 0,
  savings: 0,
  type: '',
};

ProgressBar.propTypes = {
  essential: PropTypes.number.isRequired,
  wants: PropTypes.number.isRequired,
  savings: PropTypes.number.isRequired,
  type: PropTypes.string,
};

export default withTranslation('translations')(ProgressBar);
