import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { trackView } from '../../../services/tms';
import { ANALYTICS } from '../../../constants/analytics';
import { useStepData } from 'hooks';

import store from 'store';
import Step from '../Step';
import CardCustomInput from 'components/CardCustomInput/CardCustomInput';

const STEP_ID = '10';
const PREV_STEP = '9';

const Step10 = ({ t }) => {
  const stepData = useStepData(STEP_ID);
  const [payingPension, setPayingPension] = useState(stepData?.payingPension || '');
  const [nextDisabled, setNextDisabled] = useState(true);

  const options = [
    { key: 'yes', value: t('steps.step10.option1') },
    { key: 'no', value: t('steps.step10.option2') },
  ];

  const getSelectLabelledBy = () =>
    `${t('steps.step10.line1').replace(/\s/g, '')} 
     custom-select-step-${store.getState().step}
     ${t('steps.step10.line2').replace(/\s/g, '')}`;

  const selectProps = {
    option: payingPension || '',
    optionsList: options,
    labelledBy: [getSelectLabelledBy()],
  };

  const updateStepData = (value) => {
    setPayingPension(value);
  };

  const getStepMessage = () =>
    payingPension !== '' && (
      <div
        className="step__message "
        dangerouslySetInnerHTML={{
          __html: t(`steps.step10.messages.${payingPension}`, {
            name: store.getState().survey.name,
          }),
        }}
      ></div>
    );

  useEffect(() => {
    const isNextDisabled = payingPension === '';
    setNextDisabled(isNextDisabled);
  }, [payingPension]);

  useEffect(() => {
    // const { endMonthSavings, totalSavings } = store.getState().survey;
    trackView({
      page_url: ANALYTICS.page_url.survey,
      site_section: ANALYTICS.site_section,
      page_lenguage: ANALYTICS.page_language,
      page_type: ANALYTICS.page_type,
      page_customer_group: ANALYTICS.page_customer_group,
      page_security_level: ANALYTICS.page_security_level,
      page_name: ANALYTICS.page_name.survey,
      page_category: ANALYTICS.page_category.survey,
      page_subcategory: ANALYTICS.page_subcategory,
      raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v2,
    });
  }, []);

  return (
    <Step
      prevStep={PREV_STEP}
      nextDisabled={nextDisabled}
      data={{ payingPension }}
      stepId={STEP_ID}
      tms={{
        page_url: ANALYTICS.page_url.survey,
        site_section: ANALYTICS.site_section,
        page_lenguage: ANALYTICS.page_language,
        page_type: ANALYTICS.page_type,
        page_customer_group: ANALYTICS.page_customer_group,
        page_security_level: ANALYTICS.page_security_level,
        page_name: ANALYTICS.page_name.survey,
        page_category: ANALYTICS.page_category.survey,
        page_subcategory: ANALYTICS.page_subcategory,
        raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v3,
        form_field_1: 'Step: '+ STEP_ID +'/12',
        event_category: ANALYTICS.event_category.content,
        event_details: ANALYTICS.event_action.detail,
        event_action: ANALYTICS.event_action.button,
      }}
    >
      <div className="step__question">
        <div className="step__flex step__flex--wrap t-survey">
          <p className="step__flex--title">{t('steps.step10.line1')}</p>
        </div>
        <div className="step__flex step__flex--wrap t-survey">
          <CardCustomInput
            {...selectProps}
            onSelectChange={updateStepData}
            selectState={payingPension}
            big
            dataCy="select-pension"
          />
        </div>
      </div>
      {getStepMessage()}
    </Step>
  );
};

export default withTranslation('translations')(Step10);
