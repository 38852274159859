import { StyleSheet, View } from '@react-pdf/renderer';
import { PDF } from 'constants/pdf';
import { useTranslations } from 'hooks';
import React from 'react';
import TextPdf from './TextPdf';

const InvestmentsPdf = ({ hasInvestments, investments }) => {
  const { t } = useTranslations();

  return (
    <View style={investmentsStyles.item}>
      <TextPdf color={PDF.color.black} fontWeight="thin" fontSize={15}>
        {t('pdf.investments.title_1')}
        {hasInvestments !== 'no' ? (
          <TextPdf color={PDF.color.black} fontWeight="thin" fontSize={15}>
            {t('pdf.investments.have')}
            {t('pdf.investments.title_2')}
            <TextPdf color={PDF.color.black} fontWeight="medium" fontSize={15}>
              £{investments}
            </TextPdf>
          </TextPdf>
        ) : (
          <TextPdf color={PDF.color.black} fontWeight="thin" fontSize={15}>
            {t('pdf.investments.notHave')}
            {t('pdf.investments.title_3')}
          </TextPdf>
        )}
      </TextPdf>
    </View>
  );
};

export default InvestmentsPdf;

const investmentsStyles = StyleSheet.create({
  item: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 10,
  },
});
