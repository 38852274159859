import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { orderResilienceBlocksByRating } from 'utils/rating';
import { getResilienceBlockMessages, getWorstRating } from 'utils/util';

import ResilienceBlock from '../ResilienceBlock/ResilienceBlock';

class Resilience extends Component {
  constructor(props) {
    super(props);

    const { t } = props;
    const C1 = props.scoring.C1;
    const C2 = props.scoring.C2;
    const C3 = props.scoring.C3;
    const C4 = props.scoring.C4;
    const C5 = props.scoring.C5;
    const C6 = props.scoring.C6;

    this.state = {
      blocks: [
        {
          name: t('results.resilience.spending.name'),
          icon: t('results.resilience.borrowing.icon'),
          rating: getWorstRating(C1.rating, C2.rating, C3.rating),
          ...getResilienceBlockMessages('spending', `${C1.rating}-${C2.rating}-${C3.rating}`, t),
          dataBlock: [
            {
              value: props.scoring.essentialSpendValueBlock,
              percent: props.scoring.essentialSpendPercentBlock,
              label: t('results.resilience.data.spending'),
            },
          ],
        },
        {
          name: t('results.resilience.saving.name'),
          icon: t('results.resilience.spending.icon'),
          rating: getWorstRating(C5.rating, C6.rating),
          ...getResilienceBlockMessages('saving', `${C5.rating}-${C6.rating}`, t),
          dataBlock: [
            {
              value: props.scoring.savingValueBlock,
              percent: props.scoring.savingPercentBlock,
              label: t('results.resilience.data.saving'),
            },
          ],
        },
        {
          name: t('results.resilience.borrowing.name'),
          icon: t('results.resilience.saving.icon'),
          rating: getWorstRating(C4.rating),
          ...getResilienceBlockMessages('borrowing', `${C4.rating}`, t),
          dataBlock: [
            {
              value: props.scoring.borrowingValueBlock,
              valueBlockLabel: t('results.resilience.data.total'),
            },
          ],
        },
      ],
    };
  }

  render() {
    const { t } = this.props;
    const blocks = orderResilienceBlocksByRating(this.state.blocks);
  
    return (
      <div className="resilience mb-xl">
        <div className="resilience__header">
          <h2 className="resilience__header-title">{t('results.resilience.header.title')}</h2>
          <p className="resilience__header-text">{t('results.resilience.header.text')}</p>
        </div>
        <div className="resilience__blocks">
          {blocks.map((block, index) => (
            <ResilienceBlock key={index} {...block} />
          ))}
        </div>
      </div>
    );
  }
}

Resilience.defaultProps = {
  scoring: {},
  insurances: {},
  payingPension: '',
};

Resilience.propTypes = {
  scoring: PropTypes.object,
  insurances: PropTypes.object,
  payingPension: PropTypes.string,
};

export default withTranslation('translations')(Resilience);
