import { StyleSheet, Text } from '@react-pdf/renderer';
import { PDF } from 'constants/pdf';
import React from 'react';

const TitlePdf = ({
  children,
  fontSize = PDF.font.default.title.fontSize,
  color = PDF.color.black,
  marginBottom = 10,
  fontWeight = PDF.font.default.title.fontWeight,
  lineHeight = PDF.font.lineHeight,
  debug = false,
  ...rest
}) => {
  const titleStyle = StyleSheet.create({
    fontSize: fontSize,
    lineHeight: lineHeight,
    color: color,
    marginBottom: marginBottom,
    fontWeight: fontWeight,
    ...rest,
  });

  return (
    <Text debug={debug} style={titleStyle}>
      {children}
    </Text>
  );
};

export default TitlePdf;
