import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { ANALYTICS } from 'constants/analytics';
import { trackView } from 'services/tms';
import { useSelector } from 'react-redux';

import { useStepData } from 'hooks';

import Input from 'components/Input/Input';
import Step from '../Step';

const STEP_ID = '6.a';
const PREV_STEP = '6';

const Step62 = ({ t }) => {
  const stepData = useStepData(STEP_ID);
  const { spendBasicNeeds } = useSelector(({ survey }) => survey);
  const [overpayment, setOverpayment] = useState(stepData?.overpayment || '');

  const updateStepData = (e) => {
    const { value } = e.target;
    setOverpayment(value || '');
  };

  useEffect(() => {
    trackView({
      page_url: ANALYTICS.page_url.survey,
      site_section: ANALYTICS.site_section,
      page_lenguage: ANALYTICS.page_language,
      page_type: ANALYTICS.page_type,
      page_customer_group: ANALYTICS.page_customer_group,
      page_security_level: ANALYTICS.page_security_level,
      page_name: ANALYTICS.page_name.survey,
      page_category: ANALYTICS.page_category.survey,
      page_subcategory: ANALYTICS.page_subcategory,
      raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v2,
    });
  }, [])

  return (
    <Step data={{ overpayment }} prevStep={PREV_STEP}
    stepId={STEP_ID}
    tms={{
      page_url: ANALYTICS.page_url.survey,
      site_section: ANALYTICS.site_section,
      page_lenguage: ANALYTICS.page_language,
      page_type: ANALYTICS.page_type,
      page_customer_group: ANALYTICS.page_customer_group,
      page_security_level: ANALYTICS.page_security_level,
      page_name: ANALYTICS.page_name.survey,
      page_category: ANALYTICS.page_category.survey,
      page_subcategory: ANALYTICS.page_subcategory,
      raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v3,
      form_field_1: 'Step: '+ STEP_ID +'/12',
      event_category: ANALYTICS.event_category.content,
      event_details: ANALYTICS.event_action.detail,
      event_action: ANALYTICS.event_action.button,
    }}
    >
      <div className="step__question">
        <div className="step__flex">
          <p
            className="t-survey"
            dangerouslySetInnerHTML={{ __html: t('steps.step6_2.line1', { mortgage: spendBasicNeeds?.mortgage.value }) }}
          ></p>
        </div>
        <div className="step__flex">
          <p className="t-survey">{t('steps.step6_2.line2')}</p>
        </div>
        <div className="step__flex">
          <p className="t-survey">
            {t('steps.step6_2.line3')}
            <Input
              title="Overpayment"
              type="currency"
              size={7}
              name="overpayment"
              min="0"
              placeholder="0"
              step=".01"
              value={overpayment}
              onChange={updateStepData}
              dataCy="overpayment"
            />
          </p>
        </div>
      </div>
      <div className="step__tip" dangerouslySetInnerHTML={{ __html: t('steps.step6_2.tip') }} />
    </Step>
  );
};

export default withTranslation('translations')(Step62);
