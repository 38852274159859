import {
  NEXT_STEP,
  PREV_STEP,
  RESET_STEPS,
  RESET_STORE,
  SET_MAX_STEPS,
  SET_STEP,
  SET_STEPS_COMPLETED,
  UPDATE_SURVEY,
} from '../actions';

const initialState = {
  step: 1,
  maxSteps: 12,
  stepsCompleted: false,
  survey: {
    name: '',
    age: '',
    scope: '',
    categoryWorry: {},
    financialGoal: {},
    monthlyIncome: 0,
    spendBasicNeeds: {},
    hasMonthlyDebts: '',
    monthlyDebts: {},
    monthlySavings: '',
    endMonthSavings: 0,
    totalSavings: {
      currentAccount: 0,
      savingsAccount: 0,
    },
    hasInvestments: '',
    investments: 0,
    payBillsOnTime: '',
    applicant: '',
    incomeFrequency: '',
    overpayment: '',
    insurances: {},
    payingPension: '',
  },
};

function rootReducer(state = initialState, action) {
  console.log(action)
  switch (action.type) {
    case SET_STEP:
      return {
        ...state,
        ...{
          step: action.payload,
        },
      };
    case RESET_STORE:
      return {
        ...initialState,
      };
    case SET_MAX_STEPS:
      return {
        ...state,
        ...{
          maxSteps: action.payload,
        },
      };
    case PREV_STEP:
      return {
        ...state,
        ...{
          step: state.step === 1 ? 1 : state.step - 1,
        },
      };
    case NEXT_STEP:
      return {
        ...state,
        ...{
          step: state.step === state.maxSteps ? state.maxSteps : Math.trunc(state.step) + 1,
        },
      };
    case RESET_STEPS:
      return {
        ...state,
        ...{
          step: 1,
        },
      };
    case UPDATE_SURVEY:
      return {
        ...state,
        ...{
          survey: action.payload,
        },
      };
    case SET_STEPS_COMPLETED:
      return {
        ...state,
        ...{
          stepsCompleted: action.payload,
        },
      };
    default:
      break;
  }
  return state;
}

export default rootReducer;
