import { BAD, EXCELLENT, GOOD, RATINGS_VALUE } from 'constants/ratings';

const orderByLowestRating = (blocks) =>
  blocks.sort((prev, current) => (calculatePriorityByRating(prev.rating) > calculatePriorityByRating(current.rating) ? 1 : -1));

const areAllRatingsDifferent = (ratings) => [...new Set(ratings)].length === 3;

const calculatePriorityByRating = (value) => RATINGS_VALUE.indexOf(value);

export const orderResilienceBlocksByRating = (blocks) => {
  const [spending, saving, borrowing] = blocks;
  const ratingValues = blocks
    .filter((block) => block.name === 'Spending' || block.name === 'Saving' || block.name === 'Borrowing')
    .map((block) => block.rating);

  if (ratingValues.every((rating) => rating === EXCELLENT)) {
    return [saving, spending, borrowing];
  } else if (areAllRatingsDifferent(ratingValues)) {
    return [...blocks];
  } else if (
    (ratingValues.some((rating) => rating === GOOD) && !ratingValues.includes(BAD)) ||
    ratingValues.every((rating) => rating === BAD)
  ) {
    return [spending, borrowing, saving];
  } else if (ratingValues.some((rating) => rating === BAD) && !ratingValues.includes(EXCELLENT)) {
    return [...orderByLowestRating([spending, saving, borrowing])];
  } else {
    return [...blocks];
  }
};
