import React, { useEffect, useState } from 'react';
import { Trans, withTranslation } from 'react-i18next';

import { ANALYTICS } from 'constants/analytics';
import { useStepData } from 'hooks';
import { trackView } from 'services/tms';
import store from '../../../store';

import Input from '../../Input/Input';
import Step from '../Step';

const STEP_ID = '1';

const Step1 = (props) => {
  const { t } = props;
  const defaultState = {
    name: '',
    age: '',
  };
  const isModalExit = JSON.parse(localStorage.getItem('modalExit'))

  // If we already went through this step, get the data from this step
  // If not, the state will initialize with the default empty state
  const stepData = useStepData(STEP_ID);
  const data = stepData || defaultState;

  // And set it to the state
  const [state, setState] = useState(data);
  // Additional state to check if the next button is disabled
  const [nextDisabled, setNextDisabled] = useState(true);
  const [isError, setIsError] = useState(false);
  const [comingFromHome, setComingFromHome] = useState(false);

  const updateStepData = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === 'age') {
      value = value !== '' ? parseInt(value) : '';
    }

    if (name === 'name') {
      value = value.replace(/[0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?·¬ªº¿¡`¨]/gi, '');
    }
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    trackView({
      page_url: ANALYTICS.page_url.survey,
      site_section: ANALYTICS.site_section,
      page_lenguage: ANALYTICS.page_language,
      page_type: ANALYTICS.page_type,
      page_customer_group: ANALYTICS.page_customer_group,
      page_security_level: ANALYTICS.page_security_level,
      page_name: ANALYTICS.page_name.survey,
      page_category: ANALYTICS.page_category.survey,
      page_subcategory: ANALYTICS.page_subcategory,
      raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v2,
      // funnel_name: ANALYTICS.funnel_name.financialFitnessScore,
      // funnel_step_name: ANALYTICS.funnel_step_name.nameAndAge,
    });
  }, []);

  useEffect(() => {
    const { survey, stepsCompleted } = store.getState();

    // If we are coming from the home step, set the stepData to those values
    // and go to the second step
    if (typeof stepData === 'undefined' && survey.name !== '' && survey.age !== '') {
      if (!stepsCompleted) {
        setComingFromHome(true);
      }
      setState({
        name: survey.name,
        age: survey.age,
      });
    }
    if(stepsCompleted && isModalExit) {
      setComingFromHome(true);
    }else{
      localStorage.removeItem("modalExit")
    }
  }, [stepData, isModalExit]);

  useEffect(() => {
    let isNextDisabled = state.name === '' || state.age === '' || state.age < 16;
    setNextDisabled(isNextDisabled);
    const isUnder16 = state.age.toString().length >= 1 && state.age < 16;
    setIsError(isUnder16);
  }, [state.age, state.name]);

  // pasarle la prop y en el step si tiene esa prop directamente llamar a next step
  return (
    <Step data={{ ...state }} nextDisabled={nextDisabled} fromHome={comingFromHome} 
    stepId={STEP_ID}
    tms={{
        page_url: ANALYTICS.page_url.survey,
        site_section: ANALYTICS.site_section,
        page_lenguage: ANALYTICS.page_language,
        page_type: ANALYTICS.page_type,
        page_customer_group: ANALYTICS.page_customer_group,
        page_security_level: ANALYTICS.page_security_level,
        page_name: ANALYTICS.page_name.survey,
        page_category: ANALYTICS.page_category.survey,
        page_subcategory: ANALYTICS.page_subcategory,
        raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v3,
        form_field_1: 'Step: '+ STEP_ID +'/12',
        event_category: ANALYTICS.event_category.content,
        event_details: ANALYTICS.event_action.detail,
        event_action: ANALYTICS.event_action.button,
        event_content: 'Next',
        // funnel_name: ANALYTICS.funnel_name.financialFitnessScore,
        // funnel_step_name: ANALYTICS.funnel_step_name.nameAndAge,
        // error_type: nextDisabled ? ANALYTICS.error_type.fieldValidation : 'None',
    }}
    >
      <div className="step__question step__question--step1">
        <p className="t-survey">
          <Trans i18nKey="steps.step1.line1">
            My name is
            <Input
              title="First name"
              type="text"
              placeholder={t('steps.step1.firstName')}
              name="name"
              size={8}
              value={state.name}
              onChange={updateStepData}
              dataCy="input-name"
            />
            ,
          </Trans>
        </p>
        <p className="t-survey">
          <Trans i18nKey="steps.step1.line2">
            and I'm
            <Input
              title="Age"
              type="number"
              size={4}
              placeholder={t('steps.step1.age')}
              name="age"
              min="16"
              value={state.age}
              onChange={updateStepData}
              dataCy="input-age"
              showError={isError}
            />
            years old
          </Trans>
        </p>
        {isError && (
          <p className="input__error">
            <span className="icon icon-warning" />
            {t('steps.step1.age_alert')}
          </p>
        )}
      </div>
      <div className="step__tip" dangerouslySetInnerHTML={{ __html: t('steps.step1.tip') }} />
    </Step>
  );
};

export default withTranslation('translations')(Step1);
