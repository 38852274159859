import React from 'react';
import { withTranslation } from 'react-i18next';

import { Link } from 'components/Link/Link';

const Footer = ({ t }) => {
  return (
    <footer className="footer">
      <ul className="footer__wrapper">
        <li>
          <Link to="https://www.hsbc.co.uk/privacy-notice/">{t('generic.privacy')}</Link>
        </li>
        <li>
          <Link to="https://www.hsbc.co.uk/legal/">{t('generic.terms')}</Link>
        </li>
        <li>
          <Link to="https://www.hsbc.co.uk/accessibility/">{t('generic.accesibility')}</Link>
        </li>
      </ul>
    </footer>
  );
};

export default withTranslation('translations')(Footer);
