import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { StepperContext } from 'react-material-stepper'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import store from '../../store'
import {
  updateSurvey,
  setStepsCompleted,
  setStep,
  resetStore,
} from '../../store/actions'
import { trackLink } from 'services/tms'
import useWindowSize from 'hooks/useWindowSize'
import StepsCounter from 'components/StepsCounter/StepsCounter'
import StepProgressBar from 'components/StepProgressBar/StepProgressBar'
import StepButtons from 'components/StepButtons/StepButtons'
import { Button } from 'components/Button/Button'

const mapDispatchToProps = (dispatch) => ({
  updateSurvey: (survey) => dispatch(updateSurvey(survey)),
  setStep: (step) => dispatch(setStep(step)),
  setStepsCompleted: (isCompleted) => dispatch(setStepsCompleted(isCompleted)),
  resetStore: () => dispatch(resetStore()),
})

const ConnectedStep = (props) => {
  const { resolve, goAt } = React.useContext(StepperContext)
  const { t, fromHome } = props
  const { step, maxSteps } = store.getState()

  let history = useHistory()

  const prevStep = () => {
    if (props.prevStep !== false) {
      if (props.tms) {
        const {
          page_url,
          // site_section,
          // page_lenguage,
          // page_type,
          // page_customer_group,
          // page_security_level,
          page_name,
          // page_category,
          // page_subcategory,
          raw_datalayer,
          form_field_1,
          // event_details,
          event_action,
          // event_category,
        } = props.tms
        trackLink({
          page_url: page_url,
          // event_category: event_category,
          event_action: event_action,
          event_content: 'go back',
          page_name: page_name,
          form_field_1: form_field_1,
          // page_subcategory: page_subcategory,
          raw_datalayer: raw_datalayer,
          // funnel_name: ANALYTICS.funnel_name.financialFitnessScore,
          // funnel_step_name,
        })
      }
      goAt(props.prevStep)
    }
  }

  const nextStep = () => {
    resolve(props.data)
    if (props.nextStep !== false) {
      // If custom nextStep setted, go to that step instead of to the next one
      goAt(props.nextStep)
    }
    if (props.tms) {
      const {
        page_url,
        site_section,
        page_lenguage,
        page_type,
        page_customer_group,
        page_security_level,
        page_name,
        page_category,
        page_subcategory,
        raw_datalayer,
        form_field_1,
        event_details,
        event_action,
      } = props.tms
      trackLink({
        page_url: page_url,
        site_section: site_section,
        page_lenguage: page_lenguage,
        page_type: page_type,
        page_customer_group: page_customer_group,
        page_security_level: page_security_level,
        page_name: page_name,
        page_category: page_category,
        page_subcategory: page_subcategory,
        raw_datalayer: raw_datalayer,
        form_field_1: form_field_1,
        event_details: event_details,
        event_action: event_action,
      })
    }
    const survey = {
      ...store.getState().survey,
      ...props.data,
    }
    props.updateSurvey(survey)

    if (props.data.payBillsOnTime === 'rarely' || props.data.payBillsOnTime === 'sometimes') {
      localStorage.setItem('scoring', JSON.stringify(store.getState().survey))
      history.push('/results')
    }
  }

  const calculate = () => {
    resolve(props.data)

    // Update data from the last step
    const survey = {
      ...store.getState().survey,
      ...props.data,
    }
    props.updateSurvey(survey)
    props.setStepsCompleted(true)
    props.setStep(1)

    // Go to results page
    history.push('/results')
  }

  const calculateProgress = step / maxSteps
  const size = useWindowSize()

  useEffect(() => {
    let step = props.stepId
    let maxSteps = store.getState().maxSteps

    document.title =
      'Survey ' + step + '/ ' + maxSteps + ' - HSBC Financial Fitness'

    if (fromHome) {
      props.setStep(2)
      resolve(props.data)
    }
  })

  return (
    <div className='step'>
      <h1 className='visually-hidden'>Survey {step}/10</h1>
      <div className='step__content'>{props.children}</div>
      <div className='step__navigation'>
        <div className='steps-counter'>
          {size.width > 1024 ? (
            <StepsCounter step={props.stepId} maxSteps={maxSteps} />
          ) : (
            <StepProgressBar calculateProgress={calculateProgress} />
          )}
        </div>
        <div className='step-buttons__wrapper mt-l'>
          {props.prevStep && (
            <StepButtons prevStep={prevStep} className='step-buttons--top' />
          )}
          {size.width < 1024 && (
            <div className='step-buttons__counter'>
              <span className='steps-counter__current' data-cy='current-step'>
                {t('steps.step', { steps: step })}
              </span>
              /<span className='steps-counter__total'>{maxSteps}</span>
            </div>
          )}
          {!props.calculateStep && (
            <StepButtons
              nextStep={nextStep}
              isOpen={props.isOpenModal}
              className='step-buttons--bottom'
              nextDisabled={props.nextDisabled}
            />
          )}
          {props.calculateStep && (
            <div className='step-buttons step-buttons--bottom'>
              <Button
                text={t('steps.buttons.calculate')}
                onClick={calculate}
                disabled={props.nextDisabled}
                dataCy='button-calculate'
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const Step = connect(null, mapDispatchToProps)(ConnectedStep)

Step.defaultProps = {
  prevStep: false,
  nextDisabled: false,
  nextStep: false,
  calculateStep: false,
  data: {},
  fromHome: false,
  fromResults: false,
}

Step.propTypes = {
  prevStep: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  nextDisabled: PropTypes.bool,
  nextStep: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  data: PropTypes.object,
  fromHome: PropTypes.bool,
  fromResults: PropTypes.bool,
}

export default withTranslation('translations')(Step)
