const page_url_base = '/financialfitness';
const page_category_base = 'financialfitness';
const page_name_base = `pws`;

export const ANALYTICS = {
  page_url: {
    home: page_url_base,
    howTo: `${page_url_base}/how-the-scoring-system-works`,
    survey: `/survey`,
    myFinances: `${page_url_base}/survey/my-finances`,
    monthlyIncome: `${page_url_base}/survey/monthly-income`,
    monthlySpending: `${page_url_base}/survey/monthly-spending`,
    monthlyDebtRepayments: `${page_url_base}/survey/monthly-debt-repayments`,
    monthlySavings: `${page_url_base}/survey/monthly-savings`,
    savingDetails: `${page_url_base}/survey/saving-details`,
    cashBalances: `${page_url_base}/survey/cash-balances`,
    savingDetailsCashBalances: `${page_url_base}/survey/saving-details-or-cash-balances`,
    investments: `${page_url_base}/survey/investment`,
    billsPayment: `${page_url_base}/survey/bills-payment`,
    result: `/result`,
    calculatingFor: `${page_url_base}/survey/calculating-for`,
    pension: `${page_url_base}/survey/pension`,
    insurancePolicy: `${page_url_base}/survey/insurance-policy`,
  },
  page_name: {
    home: `${page_name_base}:${page_category_base}`,
    howTo: `${page_name_base}:how the scoring system works`,
    survey: `${page_name_base}:survey`,
    myFinances: `${page_name_base}:my finances`,
    monthlyIncome: `${page_name_base}:survey:monthly income`,
    monthlySpending: `${page_name_base}:survey:monthly spending`,
    monthlyDebtRepayments: `${page_name_base}:survey:monthly debt repayments`,
    monthlySavings: `${page_name_base}:survey:monthly savings`,
    savingDetails: `${page_name_base}:survey:saving details`,
    cashBalances: `${page_name_base}:survey:cash balances`,
    savingDetailsCashBalances: `${page_name_base}:survey:saving details or cash balances`,
    investments: `${page_name_base}:survey:investment`,
    billsPayment: `${page_name_base}:survey:bills payment`,
    result: `${page_name_base}:result`,
    calculatingFor: `${page_name_base}:survey:calculating for`,
    pension: `${page_name_base}:survey:pension`,
    insurancePolicy: `${page_name_base}:survey:insurance policy`,
  },
  page_category: {
    home: page_category_base,
    howTo: 'how the scoring system works',
    survey: 'survey',
    results: 'results',
  },
  page_subcategory: 'Home',
  page_type: 'other',
  page_language: 'en_UK',
  page_customer_group: 'general',
  page_business_line: 'rbwm',
  page_security_level: '0',
  funnel_name: {
    financialFitnessScore: 'financial fitness score',
  },
  funnel_step_name: {
    nameAndAge: 'name and age',
    finances: 'my finances',
    income: 'monthly income',
    spending: 'monthly spending',
    repayment: 'monthly debt repayment',
    savings: 'monthly savings',
    savingDetails: 'saving details',
    cashBalances: 'cash balances',
    savingDetailsOrCashBalances: 'saving details or cash balances',
    investment: 'investment',
    bills: 'bills payment',
    result: 'results',
    calculatingFor: 'calculating for',
    pension: 'pension',
    insurancePolicy: 'insurance policy',
  },
  tool_name: 'financial fitness score',
  tool_event: {
    completed: 'completed',
  },
  site_section: 'pws',
  site_country: 'United Kingdom',
  site_region: 'Europe',
  event_action: {
    button: 'button',
    fieldValidation: 'field validation',
    onSite: 'onsite',
    detail: 'Track  button click for: next'
  },
  event_category: {
    content: 'content',
    error: 'error',
  },
  error_type: {
    fieldValidation: 'field validation',
  },
  raw_datalayer: {
    v1: '4345v1',
    v2: '4345v2',
    v3: '4345v3',
    v4: '4345v4',
    v5: '4345v5',
    v6: '4345v6',
    v7: '4345v7',
    v8: '4345v8',
    v9: '4345v9',
    v10: '4345v10',
    v11: '4345v11',
    v12: '4345v12',
    v13: '4345v13',
    v14: '4345v14',
    v15: '4345v15',
    v16: '4345v16',
    v17: '4345v17',
    v18: '4345v18',
    v19: '4345v19',
    v20: '4345v20',
    v21: '4345v21',
    v22: '4345v22',
    v23: '4345v23',
    v24: '4345v24',
    v25: '4345v25',
    v26: '4345v26',
    v27: '4345v27',
    v28: '4345v28',
    new_layer: {
      v1: '5270v1',
      v2: '5270v2',
      v3: '5270v3',
      v4: '5270v4',
      v5: '5270v5',
      v6: '5270v6',
      v7: '5270v7',
      v8: '5270v8',
      v9: '5270v9',
      v10: '5270v10',
    },
    new_layer_v2: {
      v1: '16518v1',
      v2: '16518v2',
      v3: '16518v3',
      v4: '16518v4',
    }
  },
};
