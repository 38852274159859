import { StyleSheet, View } from '@react-pdf/renderer';
import { useTranslations } from 'hooks';
import React, { useState } from 'react';
import { orderResilienceBlocksByRating } from 'utils/rating';
import { chunkArray, getProtectMessage, getResilienceBlockMessages, getWorstRating } from '../../utils/util';
import ResilienceBlockPdf from './ResilienceBlockPdf';
import TextPdf from './TextPdf';
import TitlePdf from './TitlePdf';

const ResiliencePdf = ({
  C1,
  C2,
  C3,
  C4,
  C5,
  C6,
  essentialSpendValueBlock,
  essentialSpendPercentBlock,
  savingValueBlock,
  savingPercentBlock,
  borrowingValueBlock,
  hasMonthlyDebts,
  insurances,
  payingPension,
}) => {
  const { t } = useTranslations();

  const [blocks] = useState(
    orderResilienceBlocksByRating([
      {
        name: t('results.resilience.spending.name'),
        icon: t('results.resilience.spending.icon'),
        dataBlock: [
          { value: essentialSpendValueBlock, percent: essentialSpendPercentBlock, label: t('results.resilience.data.spending') },
        ],
        rating: getWorstRating(C1.rating, C2.rating, C3.rating),
        ...getResilienceBlockMessages('spending', `${C1.rating}-${C2.rating}-${C3.rating}`, t),
      },
      {
        name: t('results.resilience.saving.name'),
        icon: t('results.resilience.saving.icon'),
        dataBlock: [{ value: savingValueBlock, percent: savingPercentBlock, label: t('results.resilience.data.saving') }],
        rating: getWorstRating(C5.rating, C6.rating),
        ...getResilienceBlockMessages('saving', `${C5.rating}-${C6.rating}`, t),
      },
      {
        name: t('results.resilience.borrowing.name'),
        icon: t('results.resilience.borrowing.icon'),
        dataBlock: [{ value: borrowingValueBlock, valueBlockLabel: t('results.resilience.data.total') }],
        rating: getWorstRating(C4.rating),
        ...getResilienceBlockMessages('borrowing', `${C4.rating}`, t),
      },
      {
        name: t('results.resilience.protect.name'),
        icon: t('results.resilience.protect.icon'),
        dataBlock: [],
        rating: 'good',
        showHeaderLabel: false,
        ...getProtectMessage(insurances, t),
      },
      {
        name: t('results.resilience.plan.name'),
        icon: t('results.resilience.plan.icon'),
        dataBlock: [],
        rating: 'good',
        showHeaderLabel: false,
        ...getResilienceBlockMessages('plan', payingPension, t),
      },
      {
        name: t('results.resilience.discuss.name'),
        icon: t('results.resilience.discuss.icon'),
        dataBlock: [],
        rating: 'good',
        showHeaderLabel: false,
        ...getResilienceBlockMessages('discuss', 'article1', t),
      },
    ])
  );

  return (
    <View>
      <View style={resilience.header}>
        <TitlePdf>{t('results.resilience.header.title')}</TitlePdf>
        <TextPdf>{t('results.resilience.header.text')}</TextPdf>
      </View>
      <View style={resilience.blocks}>
        {/* chunakArray is a fix because the pdf library is failing wrapping rows with flex */}
        {/* we split the array in arrays of 3 (we display 3 blocks per row)*/}
        {chunkArray(blocks, 3).map((row, rowIndex) => (
          <View key={rowIndex} style={resilience.blocks.row}>
            {row.map((block, index) =>
              hasMonthlyDebts === 'yes' && block.name === 'Borrowing' ? (
                <ResilienceBlockPdf key={index} {...block} />
              ) : (
                block.name !== 'Borrowing' && <ResilienceBlockPdf key={index} {...block} />
              )
            )}
          </View>
        ))}
      </View>
    </View>
  );
};

export default ResiliencePdf;

const resilience = StyleSheet.create({
  header: {
    width: '60%',
    title: {
      fontSize: 22,
      lineHeight: 1.2,
      marginBottom: 15,
      fontWeight: 'thin',
    },
  },
  blocks: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginVertical: 10,
    row: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 10,
    },
  },
});
