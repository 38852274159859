import React from "react"
import ReactDOM from "react-dom"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"

const Modal = ({ open, onClose }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleCloseClick = (e) => {
    e.preventDefault()
    onClose()
  }

  const goToNoResults = (e) => {
    e.preventDefault()
    localStorage.setItem("modalExit", true)
    history.push("/results")
  }

  if (!open) return null

  return ReactDOM.createPortal(
    <>
      <div className='overlay' />
      <div className='modal'>
        <div className='modal__close'></div>
        <div className='modal__content'>
          <span className="step__flex--subtitle">{t("modal.description")}</span>

          <div className='modal__buttons'>
            <button
              className='button button--primary t-button'
              target='_blank'
              onClick={handleCloseClick}
            >
              {t("modal.amend")}
            </button>
            <button
              className='button button--primary t-button'
              target='_blank'
              onClick={goToNoResults}
            >
              {t("modal.exit")}
            </button>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("survey")
  )
}

export default Modal
