import { StyleSheet, View } from '@react-pdf/renderer';
import { PDF } from 'constants/pdf';
import { useTranslations } from 'hooks';
import React from 'react';
import TextPdf from './TextPdf';

const ProgressBarPdf = ({ type, essentials, wants, savings, showLabels = true }) => {
  const { t } = useTranslations();

  return (
    <View style={progressBar.item}>
      <TextPdf color={PDF.color.black} fontSize={15} marginBottom={5}>
        {type ? t('results.balance.ideal') : t('results.balance.current')}
      </TextPdf>
      <View style={progressBar.wrapper}>
        <View style={[progressBar.row, progressBar.row[type ? 'secondary' : 'primary']]}>
          {essentials !== 0 && (
            <View style={[progressBar.row.level, progressBar.row.level.essentials, { width: `${essentials}%` }]}></View>
          )}
          {wants !== 0 && <View style={[progressBar.row.level, progressBar.row.level.wants, { width: `${wants}%` }]}></View>}
          {savings !== 0 && <View style={[progressBar.row.level, progressBar.row.level.saving, { width: `${savings}%` }]}></View>}
        </View>
        <View style={progressBar.row}>
          {essentials !== 0 && (
            <View style={[progressBar.row.text, { width: `${essentials}%` }]}>
              <TextPdf color={PDF.color.black} fontSize={10}>
                {essentials}%
              </TextPdf>
              {showLabels && <TextPdf fontSize={8}>{t('results.balance.essential')}</TextPdf>}
            </View>
          )}
          {wants !== 0 && (
            <View style={[progressBar.row.text, { width: `${wants}%`, marginLeft: 5 }]}>
              <TextPdf color={PDF.color.black} fontSize={10}>
                {wants}%
              </TextPdf>
              {showLabels && <TextPdf fontSize={8}>{t('results.balance.wants')}</TextPdf>}
            </View>
          )}
          {savings !== 0 && (
            <View style={[progressBar.row.text, { width: `${savings}%`, marginLeft: 5 }]}>
              <TextPdf color={PDF.color.black} fontSize={10}>
                {savings}%
              </TextPdf>
              {showLabels && <TextPdf fontSize={8}>{t('results.balance.savings')}</TextPdf>}
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default ProgressBarPdf;

const progressBar = StyleSheet.create({
  item: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'light',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    primary: {
      height: 25,
    },
    secondary: {
      height: 15,
    },
    level: {
      height: '100%',
      essentials: {
        backgroundColor: PDF.color.range_bar_fill,
      },
      wants: {
        backgroundColor: PDF.color.wants,
      },
      saving: {
        backgroundColor: PDF.color.savings,
      },
    },
    text: {
      display: 'flex',
      flexShrink: 1,
    },
  },
});
