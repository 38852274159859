import store from '../store'
import { BANDING } from '../constants/bandings'
import { saveScoring } from '../services/scoring'
import { deepClone } from './util'

export const calculateScoring = async () => {
  const storeState = store.getState()

  const survey = deepClone(storeState.survey)

  // if we have overpayment, we subtract from the mortgage and add it to endMonthSaving
  if (survey.overpayment !== '') {
    const { endMonthSavings } = survey
    const { value: mortgageValue } = survey.spendBasicNeeds.mortgage
    survey.spendBasicNeeds.mortgage.value = (
      +mortgageValue - +survey.overpayment
    ).toString()
    survey.endMonthSavings = (+endMonthSavings + +survey.overpayment).toString()
  }

  // If the steps aren't copleted
  if (!storeState.stepsCompleted && localStorage.getItem('scoring')) {
    return JSON.parse(localStorage.getItem('scoring'))
  }

  let scoring = {}

  // C1 calculation - Essential spend to income
  // C1 = essentialSpend / monthlyIncome
  const basicNeedSpend = Object.values(survey.spendBasicNeeds).reduce(
    (acc, need) => {
      return acc + (need.value ? parseFloat(need.value) : 0)
    },
    0
  )

  const debtPayments = Object.values(survey.monthlyDebts).reduce(
    (acc, value) => {
      return acc + (value.month ? parseFloat(value.month) : 0)
    },
    0
  )
  const basicEssentialSpend = basicNeedSpend
  const essentialSpend = basicNeedSpend + debtPayments
  const monthlyIncome = parseFloat(survey.monthlyIncome)
  const C1 = essentialSpend / monthlyIncome
  const C1_SCORE = calculateBanding('C1', C1)

  scoring.C1 = {
    value: C1,
    score: C1_SCORE.score,
    rating: C1_SCORE.rating,
  }

  // C2 calculation - Proportion of unspect income
  // C2 = amountSaved / monthlyIncome
  const amountSaved = parseFloat(survey.endMonthSavings)
  const C2 = amountSaved / monthlyIncome
  const C2_SCORE = calculateBanding('C2', C2)

  scoring.C2 = {
    value: C2,
    score: C2_SCORE.score,
    rating: C2_SCORE.rating,
  }

  // C3 calculation - Bill payment
  // C3 = payBillsOnTime
  const payBillsOnTime = survey.payBillsOnTime
  const C3 = payBillsOnTime
  const C3_SCORE = calculateBanding('C3', C3)

  scoring.C3 = {
    value: C3,
    score: C3_SCORE.score,
    rating: C3_SCORE.rating,
  }

  // C4 calculation - Debt to income
  // C4 = totalDebtBalance / monthlyIncome X 12
  const totalDebtBalance = Object.values(survey.monthlyDebts).reduce(
    (acc, value) => {
      return acc + (value.total ? parseFloat(value.total) : 0)
    },
    0
  )

  const totalDebtBalanceMonth = Object.values(survey.monthlyDebts).reduce(
    (acc, value) => {
      return acc + (value.month ? parseFloat(value.month) : 0)
    },
    0
  )

  const yearlyIncome = monthlyIncome * 12
  const C4 = totalDebtBalance / yearlyIncome
  const C4_SCORE = calculateBanding('C4', C4)

  scoring.C4 = {
    value: C4,
    score: C4_SCORE.score,
    rating: C4_SCORE.rating,
  }

  // C5 calculation - Net cash (Safety net)
  // C5 = totalSavings

  const currentAccount =
    survey.totalSavings.currentAccount === ''
      ? 0
      : parseFloat(survey.totalSavings.currentAccount)
  const savingsAccount =
    survey.totalSavings.savingsAccount === ''
      ? 0
      : parseFloat(survey.totalSavings.savingsAccount)

  const totalSavings = currentAccount + savingsAccount
  const C5 = totalSavings
  const C5_SCORE = calculateBanding('C5', C5)

  scoring.C5 = {
    value: C5,
    score: C5_SCORE.score,
    rating: C5_SCORE.rating,
  }

  // C6 calculation - Nº months emergency found
  // C6 = totalSavings / essentialSpend
  const C6 = totalSavings / essentialSpend
  const C6_SCORE = calculateBanding('C6', C6)

  scoring.C6 = {
    value: C6,
    score: C6_SCORE.score,
    rating: C6_SCORE.rating,
  }

  // Total Score
  // SCORE = C1 Score + C2 Score + C3 Score + C4 Score + C5 Score + C6 Score
  const SCORE =
    C1_SCORE.score +
    C2_SCORE.score +
    C3_SCORE.score +
    C4_SCORE.score +
    C5_SCORE.score +
    C6_SCORE.score

  scoring.total = SCORE

  // percent (needs, want, saving)

  scoring.essentialSpend = essentialSpend
  scoring.wants =
    essentialSpend >= monthlyIncome
      ? 0
      : monthlyIncome - essentialSpend - parseFloat(survey.endMonthSavings)
  scoring.saving =
    essentialSpend >= monthlyIncome ? 0 : parseFloat(survey.endMonthSavings)

  const totalPercent =
    parseFloat(scoring.essentialSpend) +
    parseFloat(scoring.wants) +
    parseFloat(scoring.saving)

  scoring.essentialPercent = Math.round(
    (scoring.essentialSpend / totalPercent) * 100
  )
  scoring.wantsPercent = Math.round((scoring.wants / totalPercent) * 100)
  scoring.savingPercent = Math.round((scoring.saving / totalPercent) * 100)
  scoring.savingPercentNotRound = (scoring.saving / totalPercent) * 100

  //Resilience block values

  scoring.essentialSpendValueBlock = basicEssentialSpend + totalDebtBalanceMonth
  scoring.essentialSpendPercentBlock = Math.round(
    (scoring.essentialSpendValueBlock * 100) / monthlyIncome
  )

  scoring.essentialDebtRepaymentsBlock = totalDebtBalanceMonth
  scoring.essentialDebtRepaymentsPercentBlock = Math.round(
    (scoring.essentialDebtRepaymentsBlock * 100) / monthlyIncome
  )

  scoring.borrowingValueBlock = totalDebtBalance

  scoring.savingValueBlock = scoring.saving
  scoring.savingPercentBlock = Math.round(
    (scoring.saving * 100) / monthlyIncome
  )
  //

  scoring.hasMonthlyDebts = survey.hasMonthlyDebts

  // Save scoring and get average score for people like me
  scoring.average = await saveResults(survey, scoring)

  scoring.monthlyIncome = monthlyIncome
  scoring.totalSavings = totalSavings

  localStorage.setItem('scoring', JSON.stringify(scoring))
  localStorage.setItem('storeState', JSON.stringify(storeState))

  return scoring
}

const calculateBanding = (band, value) => {
  const banding = BANDING[band]

  let index = banding.bandings.findIndex((elem, i, arr) => {
    let prev = arr[i > 0 ? i - 1 : 0]
    if (value <= elem || (prev < value && value <= elem)) {
      return true
    }
    return false
  })

  index = index === -1 ? banding.bandings.length - 1 : index
  return {
    score: banding.value[index],
    rating: banding.rating[index],
  }
}

const saveResults = async (survey, scoring) => {
  let { name, ...rest } = { ...survey }

  // Format data for back service
  let data = rest
  data.hasInvestments = data.hasInvestments.toUpperCase()
  data.hasMonthlyDebts = data.hasMonthlyDebts.toUpperCase()
  data.monthlySavings = data.monthlySavings.toUpperCase()
  data.payBillsOnTime = data.payBillsOnTime.toUpperCase()
  data.scope = data.scope.toUpperCase()
  data.monthlyDebts = Object.entries(data.monthlyDebts).reduce((obj, mdebt) => {
    let key = mdebt[0]
    let value = mdebt[1]
    obj[key] = {
      total: value.total === false ? 0 : value.total,
      month: value.month === false ? 0 : value.month,
    }
    return obj
  }, {})

  data.spendBasicNeeds = Object.entries(data.spendBasicNeeds).reduce(
    (obj, spend) => {
      let key = spend[0]
      let value = !spend[1].value ? 0 : spend[1].value
      obj[key] = value
      return obj
    },
    {}
  )

  data.scoring = {
    c1: scoring.C1.score,
    c2: scoring.C2.score,
    c3: scoring.C3.score,
    c4: scoring.C4.score,
    c5: scoring.C5.score,
    c6: scoring.C6.score,
    total: scoring.total,
  }

  const result = await saveScoring(data)

  return parseInt(result.data.average)
}
