import React from 'react';
import PropTypes from 'prop-types';
import { HEADER_NOT_RESULTS } from 'constants/results';
import ResultsBadCard from 'components/ResultsBadCard/ResultsBadCard';

const HeaderNotAbleResults = ({ t }) => {
  const name  =  JSON.parse(localStorage.getItem('scoring')).name || JSON.parse(localStorage.getItem('storeState')).survey.name;

  return (
    <div className="header-not-results">
      <div className="header-not-results__wrapper">
        <div className="header-not-results__title">
          <h1>{t('results.notAbleHeader.title', { name: name })}</h1>
        </div>
        <div className="header-not-results__content">
          {HEADER_NOT_RESULTS.map((item) => (
            <ResultsBadCard
              key={item.id}
              t={t}
              title={item.title}
              description={item.description}
              label={item.label}
              icon={item.icon}
              link={item.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

HeaderNotAbleResults.propTypes = {
  t: PropTypes.func.isRequired,
};

export default HeaderNotAbleResults;
