import React from 'react';

import { useTranslations } from 'hooks';

import StepProgressBar from 'components/StepProgressBar/StepProgressBar';

const StepsCounter = ({ step, maxSteps }) => {
  console.log(maxSteps)
  const calculateProgress = step / maxSteps;
  const { t } = useTranslations();

  return (
    <div className="steps-counter">
      <div className="steps-counter__steps">
        <span className="steps-counter__current" data-cy="current-step">
          {t('steps.step', { steps: step })}
        </span>
        /<span className="steps-counter__total">{maxSteps}</span>
      </div>
      <StepProgressBar calculateProgress={calculateProgress} />
    </div>
  );
};

export default StepsCounter;
