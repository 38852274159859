import { Link, StyleSheet, View } from "@react-pdf/renderer"
import { PDF } from "constants/pdf"
import { useTranslations } from "hooks"
import React from "react"
import IconPdf from "./IconPdf"
import TextPdf from "./TextPdf"

const TipBlockPdf = ({ articles }) => {
  const { t } = useTranslations()

  return (
    <View style={tipBlock.item}>
      {articles.map(({ title, description, link }, index) => (
        <View key={index} style={tipBlock.wrapper}>
          <View style={tipBlock.header}>
            <IconPdf icon='light' size='big' marginRight={3} />
            <TextPdf fontSize={14} color={PDF.color.black} maxWidth='90%'>
              {t(title)}
            </TextPdf>
          </View>
          <View style={tipBlock.content}>
            <TextPdf>{t(description)}</TextPdf>
          </View>
          <Link style={tipBlock.link} src={link}>
            {t("results.resilience.find_out_more")}
          </Link>
        </View>
      ))}
    </View>
  )
}

export default TipBlockPdf

const tipBlock = StyleSheet.create({
  item: {
    display: 'flex',
    flexDirection: 'row',
    width: '32.7%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: PDF.color.white,
    width: '100%',
    marginRight: 10
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 15,
    marginTop: 10,
    paddingVertical: 10,
    paddingRight: 15,
    paddingLeft: 5,
    maxWidth: '88%',
  },
  content: {
    marginBottom: 22,
    paddingHorizontal: 18,
    textAlign: 'justify',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 'auto',
    color: PDF.color.primary,
    fontSize: 12,
    fontWeight: 'medium',
    textDecoration: 'none',
    padding: 18,
  },
})
