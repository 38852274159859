import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import Spinner from '../../assets/images/spinner.svg';

class Loader extends Component {
  constructor(props, context) {
    super(props);
  }

  render() {
    const { className, message } = this.props;
    return (
      <section className="results main">
        <div className={`loader ${className}`}>
          <div className="loader__content">
            <img className="loader__spinner mb-l" src={Spinner} alt="Loading..." />
            {message && <p className="loader__message">{message}</p>}
          </div>
        </div>
      </section>
    );
  }
}

Loader.defaultProps = {
  className: '',
  message: '',
};

Loader.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
};

export default withTranslation('translations')(Loader);
