import { Image, StyleSheet, View } from '@react-pdf/renderer';
import React, { useState } from 'react';
import backgroundBad from '../../assets/images/results/header-scoring.webp';
import backgroundExcellent from '../../assets/images/results/header-scoring.webp';
import backgroundGood from '../../assets/images/results/header-scoring.webp';
import HeaderTopPdf from './HeaderTopPdf';

const images = {
  bad: {
    background: backgroundBad,
  },
  good: {
    background: backgroundGood,
  },
  excellent: {
    background: backgroundExcellent,
  },
};

const HeaderPdf = ({ type }) => {
  const [backgroundImage] = useState(images[type].background);

  return (
    <View style={styles.header}>
      <HeaderTopPdf />
      <Image style={styles.background} src={backgroundImage} />
    </View>
  );
};

export default HeaderPdf;

const styles = StyleSheet.create({
  header: {
    position: 'relative',
    width: '100%',
  },
  backround: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});
