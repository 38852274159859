import { StyleSheet, View } from '@react-pdf/renderer';
import { PDF } from 'constants/pdf';
import { useTranslations } from 'hooks';
import React from 'react';
import TextPdf from './TextPdf';
import TitlePdf from './TitlePdf';

const ScoreContextPdf = ({ scoring = 0, average = 0 }) => {
  const { t } = useTranslations();

  return (
    <View style={score.context}>
      <View style={score.context.info}>
        <TitlePdf color={PDF.color.white}>{t('results.score_context.title')} </TitlePdf>
      <View style={score.context.wrapper}>
        <View style={score.context.range}>
          <View style={score.context.range.row}>
            <View style={score.context.range.bar}>
              <View style={[score.context.range.bar.fill, { width: `${scoring}%` }]}></View>
              <View style={[score.context.range.bar.fill, { backgroundColor: PDF.color.pointYellow, width:'15px', 'border-radius': '100%', left: `${average - 5}%`  }]}></View>
              <View style={[score.context.range.bar.fill, { backgroundColor: PDF.color.savings, width:'15px', 'border-radius': '100%', left: `${scoring - 5}%`   }]}></View>
            </View>
            <View style={score.context.totalScore}>
              <View style={score.context.totalScore.row}>
                <View style={[score.context.range.bar.fill, { backgroundColor: PDF.color.pointYellow, width:'9px', height:'9px', marginTop: '5', left: '5' }]}></View>
                <TextPdf color={PDF.color.white} style={[score.context.totalScore.row.text]}> Avg · {average} points</TextPdf>
              </View>
              <View style={score.context.totalScore.row}>
                <View style={[score.context.range.bar.fill, { backgroundColor: PDF.color.savings, width:'9px', height:'9px', marginTop: '5', left: '7'}]}></View>
                <TextPdf color={PDF.color.white}>{t('results.score_context.you')} · {scoring} points </TextPdf>
              </View>
            </View>
          </View>
        </View>
       </View>
      </View>
    </View>
  );
};

export default ScoreContextPdf;

const score = StyleSheet.create({
  context: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    maxWidth: 450,
    info: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
    },
    wrapper: {
      display: 'flex',
      flex: '1',
      alignItems: 'center',
      maxWidth: 450,
      width: '100%',
    },
    range: {
      padding: 0,
      width: '100%',
      row: {
        alignItems: 'flex-start',
        marginBottom: 20,
      },
      bar: {
        borderRadius:'100',
        position: 'relative',
        backgroundColor: PDF.color.grey,
        opacity: 0.5,
        width: '100%',
        height: 15,
        fill: {
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: 'auto',
          backgroundColor: PDF.color.range_bar_fill,
          opacity: 1,
          borderRadius:'100',
        },
      },
    },
    totalScore: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginTop: 5,
      row: {
        display: 'inline-flex',
        alignItems: 'flex-start',
        width: '50%'
      }
    }
  },
});
