import { StyleSheet, View } from '@react-pdf/renderer';
import { PDF } from 'constants/pdf';
import { useTranslations } from 'hooks';
import React from 'react';
import IconPdf from './IconPdf';
import TextPdf from './TextPdf';

const MonthlyDebtsPdf = ({ monthlyDebts }) => {
  const { t } = useTranslations();

  return (
    <View style={debts.item}>
      <TextPdf fontSize={15} color={PDF.color.black} fontWeight="thin">
        {t('pdf.monthlyDebts.title')}
      </TextPdf>
      <View style={debts.blocks}>
        {Object.entries(monthlyDebts).map(([debt, ...debtValues], index) => (
          <View style={debts.blocks.block} key={index}>
            <View style={debts.blocks.block.name}>
              <IconPdf icon={t(`pdf.monthlyDebts.categories.${debt}.icon`)} marginRight={5} />
              <TextPdf color={PDF.color.black} fontWeight="thin">
                {t(`pdf.monthlyDebts.categories.${debt}.name`)}
              </TextPdf>
            </View>
            <View style={debts.blocks.block.row}>
              <View style={debts.blocks.block.debt}>
                <TextPdf fontWeight="thin" fontSize={10}>
                  {t('pdf.monthlyDebts.total')}
                </TextPdf>
                <TextPdf color={PDF.color.black} fontWeight="thin">
                  £{debtValues[0].total || 0}
                </TextPdf>
              </View>
              <View style={debts.blocks.block.value}>
                <TextPdf fontWeight="thin" fontSize={10}>
                  {t('pdf.monthlyDebts.monthly')}
                </TextPdf>
                <TextPdf color={PDF.color.black} fontWeight="thin" alignSelf="flex-end">
                  £{debtValues[0].month || 0}
                </TextPdf>
              </View>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

export default MonthlyDebtsPdf;

const debts = StyleSheet.create({
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  blocks: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    block: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginVertical: 8,
      name: {
        display: 'flex',
        flexDirection: 'row',
      },
      row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      debt: {
        display: 'flex',
        flexDirection: 'column',
        name: {
          display: 'flex',
          flexDirection: 'row',
        },
      },
      value: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
});
