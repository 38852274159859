import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'

import { ANALYTICS } from 'constants/analytics'
import { useStepData } from 'hooks'
import { trackView } from 'services/tms'
import { formatNumberWith2Decimals } from 'utils/util'
import { BASIC_NEEDS } from '../../../constants/survey'
import store from '../../../store'

import Step from '../Step'
import CustomMonthlyCard from 'components/CustomMonthlyCard/CustomMonhtlyCard'

const STEP_ID = '6'
const ALT_NEXT_STEP = '7'
const PREV_STEP = '5'



const Step6 = ({ t }) => {

  const getCleanState = () => {
    return BASIC_NEEDS.reduce((prev, current) => {
      return { ...prev, [current]: { frequency: 'monthly', value: '0', valuePrev: '', selected: false} }
    }, {})
  }

  const defaultState = getCleanState()
  const stepData = useStepData(STEP_ID)?.spendBasicNeeds
  const data = stepData || defaultState;
  const [state, setState] = useState(data)

  const [nextDisabled, setNextDisabled] = useState(false)
  const [nextStepId, setNexStepId] = useState(false)
  const [totalSpends, setTotalSpends] = useState(0)


  const updateStepData = (e) => {
    setState({...state, ...e})
};

  useEffect(() => {
    // const { monthlyIncome, incomeFrequency } = store.getState().survey

    // const income =
    //   incomeFrequency === 'weekly'
    //     ? getWeeklyValue(monthlyIncome)
    //     : monthlyIncome

        trackView({
          page_url: ANALYTICS.page_url.survey,
          site_section: ANALYTICS.site_section,
          page_lenguage: ANALYTICS.page_language,
          page_type: ANALYTICS.page_type,
          page_customer_group: ANALYTICS.page_customer_group,
          page_security_level: ANALYTICS.page_security_level,
          page_name: ANALYTICS.page_name.survey,
          page_category: ANALYTICS.page_category.survey,
          page_subcategory: ANALYTICS.page_subcategory,
          raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v2,
          // funnel_name: ANALYTICS.funnel_name.financialFitnessScore,
          // funnel_step_name: ANALYTICS.funnel_step_name.nameAndAge,
        });
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const isDisable = () => {
      return Object.entries(state).some(
        (element) => 
           element[1].selected && element[1].value !== '0' && element[1].value !== ''
      )
    }
    setNextDisabled(!isDisable())
    let newNextStep =
      state.mortgage.value === '0' || state.mortgage.value === ''
        ? ALT_NEXT_STEP
        : false
    setNexStepId(newNextStep)
    setTotalSpends(
      Object.values(state).reduce((acc, { value }) => {
        return acc + (value ? parseFloat(value) : 0)
      }, 0)
    )
  }, [state, setState])

  return (
    <Step
      data={{ spendBasicNeeds: { ...state } }}
      stepId={STEP_ID}
      prevStep={PREV_STEP}
      nextDisabled={nextDisabled}
      nextStep={nextStepId}
      isOpenModal={
        parseInt(store.getState().survey.monthlyIncome) <= parseInt(totalSpends)
      }
      tms={{
        page_url: ANALYTICS.page_url.survey,
        site_section: ANALYTICS.site_section,
        page_lenguage: ANALYTICS.page_language,
        page_type: ANALYTICS.page_type,
        page_customer_group: ANALYTICS.page_customer_group,
        page_security_level: ANALYTICS.page_security_level,
        page_name: ANALYTICS.page_name.survey,
        page_category: ANALYTICS.page_category.survey,
        page_subcategory: ANALYTICS.page_subcategory,
        raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v3,
        form_field_1: 'Step: '+ STEP_ID +'/12',
        event_category: ANALYTICS.event_category.content,
        event_details: ANALYTICS.event_action.detail,
        event_action: ANALYTICS.event_action.button,
      }}
    >
      <div className='step__question'>
        <p
          id='text-spends'
          className='t-survey step__flex--title'
          aria-live='polite'
          aria-atomic='true'
        >
          {t('steps.step6.line1')}
          <span id='spends' className='step__spends-sum'>
            £ {formatNumberWith2Decimals(totalSpends)}
          </span>
          {t('steps.step6.line2')}
        </p>
        <div
          className='step__flex--subtitle'
          dangerouslySetInnerHTML={{ __html: t('steps.step6.tip') }}
        />
        <div className='step__essential'>
          {Object.entries(state).map((need, index) => {
            return (
              <CustomMonthlyCard
                t={t}
                key={need[0]}
                index={index}
                need={need}
                setState={setState}
                onSelectChange={updateStepData}
              />
            )
          })}
        </div>
      </div>
    </Step>
  )
}

export default withTranslation('translations')(Step6)
