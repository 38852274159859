import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import { ANALYTICS } from 'constants/analytics'
import { trackView } from 'services/tms'
import store from 'store'

import { getMessagesType } from 'utils/util'
import { calculateScoring } from '../../utils/scoring'
import PropTypes from 'prop-types'

import Loader from '../../components/Loader/Loader'
import NotAbleResults from '../../components/NotAbleResults/NotAbleResults'
import AbleResults from '../../components/AbleResults/AbleResults'

class Results extends Component {
  constructor(props) {
    super(props)

    this.state = {
      scoring: false,
      messagesType: 'excellent',
    }
  }

  componentDidMount() {
    calculateScoring().then((response) => {
      // const { insurances } = store.getState().survey
      // trackView({
      //   page_url: ANALYTICS.page_url.result,
      //   page_name: ANALYTICS.page_name.result,
      //   page_subcategory: ANALYTICS.page_subcategory.result,
      //   raw_datalayer: ANALYTICS.raw_datalayer.v24,
      //   funnel_name: ANALYTICS.funnel_name.financialFitnessScore,
      //   funnel_step_name: ANALYTICS.funnel_step_name.result,
      //   form_field_1: `insurance policies:${Object.entries(insurances)
      //     .map(
      //       ([key, value]) =>
      //         `${key.replace(/([A-Z])/g, ' $1').toLowerCase()}:${value || 'no'}`
      //     )
      //     .join(':')}`,
      //   form_field_2: `your financial score:${response.total}`,
      //   form_field_3: `average financial score:${response.average}`,
      // })
      trackView({
        page_url: ANALYTICS.page_url.result,
        site_section: ANALYTICS.site_section,
        page_lenguage: ANALYTICS.page_language,
        page_type: ANALYTICS.page_type,
        page_customer_group: ANALYTICS.page_customer_group,
        page_security_level: ANALYTICS.page_security_level,
        page_name: ANALYTICS.page_name.survey,
        page_category: ANALYTICS.page_category.results,
        page_subcategory: ANALYTICS.page_subcategory,
        raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v4,
      });
      this.setState({
        scoring: response,
        messagesType: getMessagesType(response.total),
      })
      // Steps are not completed, so return to survey page
      if (this.state.scoring === false) {
        return this.props.history.push('/survey')
      }
    })
  }

  render() {
    const isModalExit = JSON.parse(localStorage.getItem('modalExit'))

    const { t } = this.props
    const { scoring, messagesType,  } = this.state
    const { insurances, payingPension } = store.getState().survey
  
    if (scoring === false) {
      return (
        <Loader
          className='results__loader'
          message={t('results.loader.message')}
        />
      )
    }

    if (isModalExit) {
      return <NotAbleResults t={t} />
    }
   
    return (
        <AbleResults
          t={t}
          messagesType={messagesType}
          scoring={scoring}
          insurances={insurances}
          payingPension={payingPension}
        />
    )
  }
}

Results.propTypes = {
  t: PropTypes.func.isRequired,

}

export default withTranslation('translations')(Results)
