import React from 'react';

import InputCustom from 'components/InputCustom/InputCustom';

const CustomLabel = ({
  dataKey,
  dataValue,
  selectState,
  dataCy,
  disabled,
  onClick,
  option,
  step,
  type,
  valueInput,
  setValueInput,
  onChange
}) => {

  const idSelect = 'custom-select-step-' + step;
  const isSelected = type === 'checkbox' ? selectState[option.key] === 'yes' : valueInput === option.key;
  const selectClass =
    isSelected ? 't-survey custom-select-container--selected' : 't-survey custom-select-container';

  return (
    <label
      key={dataKey}
      data-key={dataKey}
      data-value={dataValue}
      data-cy={dataCy}
      disabled={disabled}
      className={selectClass}
      onClick={onClick}
      onChange={onChange}
    >
      {option.value}
      <InputCustom
        type={type}
        value={option.key}
        selectState={selectState}
        name={idSelect}
        id={idSelect}
        setValueInput={setValueInput}
      />
    </label>
  );
};

export default CustomLabel;
