import { Document, Page, StyleSheet, View } from '@react-pdf/renderer';
import { PDF } from 'constants/pdf';
import { useTranslations } from 'hooks';
import React, { useState } from 'react';
import { registerPdfFonts } from 'utils/pdf';
import { getMessagesType } from 'utils/util';
import BalancePdf from './BalancePdf';
import EssentialsSpendPdf from './EssentialsSpendPdf';
import FooterPdf from './FooterPdf';
import HeaderPdf from './HeaderPdf';
import InvestmentsPdf from './InvestmentsPdf';
import MonthlyDebtsPdf from './MonthlyDebtsPdf';
import MonthlySavingsPdf from './MonthlySavingsPdf';
import ResiliencePdf from './ResiliencePdf';
import ScoringPdf from './ScoringPdf';
import TipsPdf from './TipsPdf';
import TitlePdf from './TitlePdf';

const ScorePdf = ({ scoring = {}, survey = {} }) => {
  const { t } = useTranslations();
  const [scoreType] = useState(getMessagesType(scoring?.total));
  registerPdfFonts();

  return (
    <Document producer={PDF.producer} creator={pdf.creator}>
      <Page size="A4" style={pdf.page}>
        <HeaderPdf type={scoreType} />
        <ScoringPdf total={scoring?.total} average={scoring?.average} name={survey?.name} type={scoreType} />
        <View style={pdf.wrapper.small}>
          <BalancePdf essentials={scoring?.essentialPercent} wants={scoring?.wantsPercent} savings={scoring?.savingPercent} />
        </View>
      </Page>

      <Page style={pdf.page}>
        <View style={pdf.wrapper.small}>
          <ResiliencePdf {...scoring} insurances={survey?.insurances} payingPension={survey?.payingPension} />
          {/* <SavingPdf
            totalSavings={scoring?.totalSavings}
            savings={scoring?.saving}
            savingPercent={scoring?.savingPercent}
            monthlyIncome={scoring?.monthlyIncome}
            emergencyFundMonths={scoring?.C6.value}
          /> */}
        </View>
        <View style={pdf.wrapper.surveyExperience} break>
          <TipsPdf {...scoring} scoring={scoring} />
        </View>
      </Page>

      <Page style={[pdf.page]}>
        <View style={pdf.wrapper.financialInfo}>
          <TitlePdf fontSize={22} marginBottom={15}>
            {t('pdf.financialInformation')}
          </TitlePdf>
          <EssentialsSpendPdf spendBasicNeeds={survey?.spendBasicNeeds} />
          <MonthlyDebtsPdf monthlyDebts={survey?.monthlyDebts} />
        </View>
      </Page>
      <Page style={pdf.page}>
        <View style={pdf.wrapper.financialInfo}>
          <MonthlySavingsPdf endMonthSavings={survey?.endMonthSavings} totalSavings={survey?.totalSavings} />
          <InvestmentsPdf hasInvestments={survey?.hasInvestments} investments={survey?.investments} />
        </View>
        <FooterPdf />
      </Page>
    </Document>
  );
};

export default ScorePdf;

const pdf = StyleSheet.create({
  page: {
    backgroundColor: PDF.color.bg_page,
    fontFamily: PDF.font.family,
  },
  wrapper: {
    small: {
      paddingHorizontal: 32,
      paddingVertical: 32,
    },
    surveyExperience: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
    },
    financialInfo: {
      paddingHorizontal: 32,
      paddingVertical: 16,
    },
  },
});
