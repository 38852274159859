import { Link, StyleSheet, Text, View } from '@react-pdf/renderer';
import { PDF } from 'constants/pdf';
import { useTranslations } from 'hooks';
import React from 'react';
import { formatNumberWith2Decimals } from 'utils/util';
import IconPdf from './IconPdf';
import TextPdf from './TextPdf';

const ResilienceBlockPdf = ({ name, dataBlock, top, rating, showHeaderLabel = true, icon }) => {
  const { t } = useTranslations();

  return (
    <View style={[resilienceBlock.item, resilienceBlock.item[rating]]} minPresenceAhead={2000}>
      <View style={resilienceBlock.header}>
        <IconPdf icon={icon} />
        <TextPdf fontSize={15} flexGrow={1} marginHorizontal={4} color={PDF.color.black}>
          {name}
        </TextPdf>
        {showHeaderLabel && (
          <Text style={[resilienceBlock.header.label, resilienceBlock.header.label[rating]]}>
            {t(`results.resilience.ratings.${rating}`)}
          </Text>
        )}
      </View>
      {dataBlock &&
        dataBlock.map(({ value, valueBlockLabel, percent, label }, index) => (
          <View style={resilienceBlock.data} key={index}>
            <TextPdf fontSize={10} minHeight={16}>
              {label}
            </TextPdf>
            <View>
              <View style={resilienceBlock.data.wrapper}>
                <View style={resilienceBlock.data.block}>
                  <TextPdf fontSize={15} color={PDF.color.black}>
                    £{formatNumberWith2Decimals(value)}
                  </TextPdf>
                  <TextPdf fontSize={10}>{valueBlockLabel || t('results.resilience.data.month')}</TextPdf>
                </View>
                {percent > 0 && (
                  <View
                    style={[resilienceBlock.data.block, { borderLeft: `1 solid ${PDF.color.resilience_block}`, paddingLeft: 5 }]}
                  >
                    <TextPdf fontSize={15} color={PDF.color.black}>
                      {percent}%
                    </TextPdf>
                    <TextPdf fontSize={10}>&nbsp;{t('results.resilience.data.increment')}</TextPdf>
                  </View>
                )}
              </View>
            </View>
          </View>
        ))}
      <View style={resilienceBlock.data.block.tip}>
        <TextPdf fontSize={10} color={PDF.color.black}>
          {top.title}
        </TextPdf>
      </View>
      <View style={resilienceBlock.data.block.action}>
        <TextPdf fontSize={10} display="flex" flexDirection="row">
          {top.text}
        </TextPdf>
      </View>
      <View style={resilienceBlock.data.block.link.wrapper}>
        <Link style={resilienceBlock.data.block.link} src={top.link}>
          {top.cta}
        </Link>
      </View>
    </View>
  );
};

export default ResilienceBlockPdf;

const resilienceBlock = StyleSheet.create({
  item: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    width: '31%',
    height: '100%',
    minWidth: 172,
    paddingHorizontal: 5,
    paddingVertical: 10,
    backgroundColor: PDF.color.white,
    marginRight: 10,
    marginTop: 10,
    fontWeight: 'light',
    position: 'relative',
    minHeight: 300,
    good: {
      borderTop: `2 solid ${PDF.color.good}`,
    },
    excellent: {
      borderTop: `2 solid ${PDF.color.excellent}`,
    },
    bad: {
      borderTop: `2 solid ${PDF.color.bad}`,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 5,
    label: {
      paddingVertical: 3,
      paddingHorizontal: 5,
      fontSize: 10,
      textTransform: 'capitalize',
      good: {
        backgroundColor: PDF.color.good,
        color: PDF.color.white,
      },
      excellent: {
        backgroundColor: PDF.color.excellent,
        color: PDF.color.white,
      },
      bad: {
        backgroundColor: PDF.color.bad,
        color: PDF.color.black,
      },
    },
  },
  data: {
    display: 'flex',
    paddingVertical: 5,
    paddingLeft: 5,
    flexDirection: 'column',
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    block: {
      paddingRight: 5,
      paddingVertical: 3,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      tip: {
        paddingVertical: 1,
        paddingHorizontal: 5,
      },
      action: {
        paddingHorizontal: 5,
        paddingBottom: 5,
      },
      link: {
        fontSize: 10,
        textAlign: 'center',
        border: `1 solid ${PDF.color.black}`,
        paddingVertical: 4,
        color: PDF.color.black,
        fontWeight: 'medium',
        textDecoration: 'none',
        wrapper: {
          display: 'flex',
          marginTop: 'auto',
        },
      },
    },
  },
});
