import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import CustomLabel from 'components/CustomLabel/CustomLabel';

const CardCustomInput = ({ optionsList, step, selectState, dataCy, disable, onSelectChange, type, checkbox, isCheck, setIsCheck, defaultState }) => {
  const [selectText, setSelectText] = useState('');
  const [valueInput, setValueInput] = useState('');

  const handleOptionClick = (e) => {
    // Checkbox
      if(type === 'checkbox' && e.target.value !== undefined) {
        setSelectText({
          ...selectText,
          ...{
            [e.target.value]: e.target.checked ? 'yes' : '',
          }
        })
        onSelectChange({
          [e.target.value]: e.target.checked ? 'yes' : ''
        })

  // Radiobuttons
      }else{
        setSelectText(e);
        onSelectChange(selectText);
      }
  };

  useEffect(() => {
    setSelectText(selectState);
    setValueInput(selectState);

  }, [selectState]);
  
  return (
    <div className="custom-select">
      {optionsList.map((option) => {
        return (
          <CustomLabel
            key={option.key}
            dataKey={option.key}
            selectState={selectState}
            dataValue={option.value}
            dataCy={dataCy}
            disabled={disable === option.key}
            onClick={ !checkbox ? () => handleOptionClick(option.key) : () =>  {}}
            onChange={ checkbox ? handleOptionClick : () => {}}
            option={option}
            type={type}
            step={step}
            valueInput={valueInput}
            setValueInput={setValueInput}
          >
            {option.value}
          </CustomLabel>
        );
      })}
    </div>
  );
};

CardCustomInput.defaultProps = {
  defaultText: {},
  option: '',
  disable: '',
  optionsList: [],
  onSelectChange: undefined,
  type: 'radio',
  font: 't-survey',
  step: 0,
};

CardCustomInput.propTypes = {
  defaultText: PropTypes.object.isRequired,
  aria: PropTypes.array,
  option: PropTypes.string,
  disable: PropTypes.string,
  optionsList: PropTypes.array,
  onSelectChange: PropTypes.func,
  font: PropTypes.string,
  type: PropTypes.string,
  step: PropTypes.number,
};

export default CardCustomInput;
