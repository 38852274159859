import React, { Component, Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Page from 'components/Page/Page';
import Home from 'pages/Home/Home';
import Results from 'pages/Results/Results';
import Survey from 'pages/Survey/Survey';
import Loader from 'components/Loader/Loader';

const HowTo = lazy(() => import('pages/HowTo/HowTo'));
const NoMatch = lazy(() => import('pages/NoMatch/NoMatch'));

// Home
// Survey
// Score result
// How the scoring system works
// Page not found

export class Main extends Component {
  render() {
    return (
      <main className="container">
        <Switch>
          <Route exact path="/" render={(props) => <Page {...props} component={Home} title="Home | HSBC Financial Fitness" />} />
          <Route
            path="/howto"
            render={(props) => (
              <Suspense fallback={<Loader />}>
                <Page {...props} component={HowTo} title="How the scoring system works | HSBC Financial Fitness" />
              </Suspense>
            )}
          />
          <Route
            path="/survey"
            render={(props) => <Page {...props} component={Survey} title="Survey | HSBC Financial Fitness" />}
          />
          <Route
            path="/results"
            render={(props) => <Page {...props} component={Results} title="Score Result | HSBC Financial Fitness" />}
          />
          <Route
            path="/error"
            render={(props) => (
              <Suspense fallback={<Loader />}>
                <Page {...props} component={NoMatch} title="Page not found | HSBC Financial Fitness" />
              </Suspense>
            )}
          />
          <Redirect from="*" to="/error" />
        </Switch>
      </main>
    );
  }
}
