import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { ANALYTICS } from 'constants/analytics';
import { useStepData } from 'hooks';
import { trackView } from 'services/tms';

import Input from '../../Input/Input';
import Step from '../Step';

const STEP_ID = '9';
const PREV_STEP = '8';

const Step9 = (props) => {
  const { t } = props;
  const defaultState = {
    currentAccount: '',
    savingsAccount: '',
  };

  // If we already went through this step, get the data from this step
  // If not, the state will initialize with the default empty state
  const stepData = useStepData(STEP_ID)?.totalSavings;
  const data = stepData || defaultState;

  // And set it to the state
  const [state, setState] = useState(data);

  // Additional state to check if the next button is disabled
  const [nextDisabled, setNextDisabled] = useState(true);

  const updateStepData = (e) => {
    let value = e.target.value !== '' ? e.target.value : '';
    setState({ ...state, [e.target.name]: value });
  };

  useEffect(() => {
    // const { monthlySavings, endMonthSavings } = store.getState().survey;

    trackView({
      page_url: ANALYTICS.page_url.survey,
      site_section: ANALYTICS.site_section,
      page_lenguage: ANALYTICS.page_language,
      page_type: ANALYTICS.page_type,
      page_customer_group: ANALYTICS.page_customer_group,
      page_security_level: ANALYTICS.page_security_level,
      page_name: ANALYTICS.page_name.survey,
      page_category: ANALYTICS.page_category.survey,
      page_subcategory: ANALYTICS.page_subcategory,
      raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v2,
    });
  }, []);

  useEffect(() => {
    let isNextDisabled = state.savingsAccount === '' && state.currentAccount === '';
    setNextDisabled(isNextDisabled);
  }, [state.currentAccount, state.savingsAccount]);

  const getStepMessage = () => {
    let message = [];
    if (state.currentAccount !== '' && state.currentAccount > 0) {
      message.push(
        <div
          key="current"
          className="step__message mb-m"
          dangerouslySetInnerHTML={{ __html: t('steps.step9.current_message') }}
        ></div>
      );
    }
    if (state.savingsAccount !== '' && state.savingsAccount > 0) {
      message.push(
        <div key="savings" className="step__message" dangerouslySetInnerHTML={{ __html: t('steps.step9.savings_message') }}></div>
      );
    }
    if (state.currentAccount !== '' && state.currentAccount > 0 && state.savingsAccount !== '' && state.savingsAccount > 0) {
      message = [];
      message.push(
        <div key="savings" className="step__message" dangerouslySetInnerHTML={{ __html: t('steps.step9.both_message') }}></div>
      );
    }
    return message;
  };

  return (
    <Step
      data={{ totalSavings: { ...state } }}
      stepId={STEP_ID}
      prevStep={PREV_STEP}
      nextDisabled={nextDisabled}
      tms={{
        page_url: ANALYTICS.page_url.survey,
        site_section: ANALYTICS.site_section,
        page_lenguage: ANALYTICS.page_language,
        page_type: ANALYTICS.page_type,
        page_customer_group: ANALYTICS.page_customer_group,
        page_security_level: ANALYTICS.page_security_level,
        page_name: ANALYTICS.page_name.survey,
        page_category: ANALYTICS.page_category.survey,
        page_subcategory: ANALYTICS.page_subcategory,
        raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v3,
        form_field_1: 'Step: '+ STEP_ID +'/12',
        event_category: ANALYTICS.event_category.content,
        event_details: ANALYTICS.event_action.detail,
        event_action: ANALYTICS.event_action.button,
      }}
    >
      <div className="step__question">
        <p className="t-survey mb-l">{t('steps.step9.line1')}</p>
        <div className="step__savings step__essential">
          <div className="step__essential-item">
            <div className="step__essential-block">
              <i aria-hidden="true" className="step__essential-icon step__essential-icon--creditCard"></i>
              <p className="step__esential-title step__esential-title--spends">{t('steps.step9.current_account')}</p>
              <div className="s-table__input">
                <label className="visually-hidden" htmlFor="currentAccount">
                  {t('steps.step9.current_account')}
                </label>
                <Input
                  id="currentAccount"
                  title="Current account"
                  type="currency"
                  size={6}
                  font="t-h3"
                  name="currentAccount"
                  min="0"
                  step=".01"
                  value={state.currentAccount}
                  onChange={updateStepData}
                  dataCy="current-account"
                  border
                  placeholder={t('steps.step9.placeholder')}
                />
              </div>
            </div>
          </div>
          <div className="step__essential-item">
            <div className="step__essential-block">
              <i aria-hidden="true" className="step__essential-icon step__essential-icon--loans"></i>
              <p className="step__esential-title step__esential-title--spends">{t('steps.step9.savings_account')}</p>
              <div className="s-table__input">
                <label className="visually-hidden" htmlFor="savingsAccount">
                  {t('steps.step9.savings_account')}
                </label>
                <Input
                  id="savingsAccount"
                  title="Savings account"
                  type="currency"
                  size={6}
                  font="t-h3"
                  name="savingsAccount"
                  min="0"
                  step=".01"
                  value={state.savingsAccount}
                  onChange={updateStepData}
                  dataCy="savings-account"
                  border
                  placeholder={t('steps.step9.placeholder')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {getStepMessage()}
    </Step>
  );
};

export default withTranslation('translations')(Step9);
