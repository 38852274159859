import React, { Component } from 'react';
import { Main } from 'components/Main/Main';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';

import { withRouter } from 'react-router';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: 'en',
    };
  }

  render() {
    const { location } = this.props;

    return (
      <div className="App">
        <ScrollToTop>
          <Header />
          <Main />
          {location.pathname !== '/survey' && <Footer />}
        </ScrollToTop>
      </div>
    );
  }
}

export default withRouter(App);
