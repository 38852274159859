import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { ANALYTICS } from 'constants/analytics';
import { useStepData } from 'hooks';
import { trackView } from 'services/tms';

import CardCustomInput from '../../CardCustomInput/CardCustomInput';
import Step from '../Step';

const STEP_ID = '4.a';
const PREV_STEP = '4';
const ALT_NEXT_STEP = '5';
// const STEP_ID_CATEGORY_WORRY = '4.2';

const defaultState = {
    budgetBetter: '',
    manageYourDebt: '',
    growYourMoney: '',
    somethingSpecial: '',
    startingInvest: '',
    savingRetirement: '',
};

const Step43 = ({ t }) => {
  const stepData = useStepData(STEP_ID)?.financialGoal;
  const data = stepData || defaultState;
  const [state, setState] = useState(data);
  const [nextDisabled, setNextDisabled] = useState(true);

  const options = [
    { key: 'budgetBetter', value: t('steps.step3_3.option1') },
    { key: 'manageYourDebt', value: t('steps.step3_3.option2') },
    { key: 'growYourMoney', value: t('steps.step3_3.option3') },
    { key: 'somethingSpecial', value: t('steps.step3_3.option4') },
    { key: 'startingInvest', value: t('steps.step3_3.option5') },
    { key: 'savingRetirement', value: t('steps.step3_3.option6') },
  ];

  const updateStepData = (e) => {
    setState({...state, ...e})
  };

  useEffect(() => {
    let res = Object.values(state).filter(element => element === 'yes')
    let isNextDisabled = res.length !== 0 && res.length <=3 ? false : true
    setNextDisabled(isNextDisabled);
  }, [state]);

  useEffect(() => {
    // const { applicant } = store.getState().survey;
    trackView({
      page_url: ANALYTICS.page_url.survey,
      site_section: ANALYTICS.site_section,
      page_lenguage: ANALYTICS.page_language,
      page_type: ANALYTICS.page_type,
      page_customer_group: ANALYTICS.page_customer_group,
      page_security_level: ANALYTICS.page_security_level,
      page_name: ANALYTICS.page_name.survey,
      page_category: ANALYTICS.page_category.survey,
      page_subcategory: ANALYTICS.page_subcategory,
      raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v2,
      // funnel_name: ANALYTICS.funnel_name.financialFitnessScore,
      // funnel_step_name: ANALYTICS.funnel_step_name.nameAndAge,
    });
  }, []);

  return (
    <Step
      nextDisabled={nextDisabled}
      stepId={STEP_ID}
      data={{financialGoal: {...state}, categoryWorry: {}}}
      prevStep={PREV_STEP}
      nextStep={ALT_NEXT_STEP}
      tms={{
        page_url: ANALYTICS.page_url.survey,
        site_section: ANALYTICS.site_section,
        page_lenguage: ANALYTICS.page_language,
        page_type: ANALYTICS.page_type,
        page_customer_group: ANALYTICS.page_customer_group,
        page_security_level: ANALYTICS.page_security_level,
        page_name: ANALYTICS.page_name.survey,
        page_category: ANALYTICS.page_category.survey,
        page_subcategory: ANALYTICS.page_subcategory,
        raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v3,
        form_field_1: 'Step: '+ STEP_ID +'/12',
        event_category: ANALYTICS.event_category.content,
        event_details: ANALYTICS.event_action.detail,
        event_action: ANALYTICS.event_action.button,
    }}
    >
      <div className="step__question">
        <div className="step__flex">
          <p id={t('steps.step3_3.line1').replace(/\s/g, '')} className="t-survey step__flex--title">
            {t('steps.step3_3.line1')}&nbsp;
          </p>
          <CardCustomInput
            type="checkbox"
            selectState={state}
            optionsList={options}
            onSelectChange={updateStepData}
            step={4.3}
            dataCy="select-category"
            checkbox
          />
        </div>
      </div>
    </Step>
  );
};

export default withTranslation('translations')(Step43);
