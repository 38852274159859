import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { setMaxSteps } from '../../store/actions';
import store from '../../store'
import Stepper, { Step } from 'react-material-stepper';
import Step1 from '../../components/Step/Step1/Step1';
import Step2 from '../../components/Step/Step2/Step2';
import Step3 from '../../components/Step/Step3/Step3';
import Step4 from '../../components/Step/Step4/Step4';
import Step5 from '../../components/Step/Step5/Step5';
import Step6 from '../../components/Step/Step6/Step6';
import Step7 from '../../components/Step/Step7/Step7';
import Step8 from '../../components/Step/Step8/Step8';
import Step9 from '../../components/Step/Step9/Step9';
import Step10 from '../../components/Step/Step10/Step10';
import Step11 from '../../components/Step/Step11/Step11';
import Step12 from '../../components/Step/Step12/Step12';
import Step42 from '../../components/Step/Step4/Step42';
import Step43 from '../../components/Step/Step4/Step43';
import Step62 from 'components/Step/Step6/Step62';

const STEPS = [
  {
    id: '1',
    step: Step1,
  },
  {
    id: '2',
    step: Step2,
  },
  {
    id: '3',
    step: Step3,
  },
  {
    id: '4',
    step: Step4,
  },
  {
    id: '4.b',
    step: Step42,
  },
  {
    id: '4.a',
    step: Step43,
  },
  {
    id: '5',
    step: Step5,
  },
  {
    id: '6',
    step: Step6,
  },
  {
    id: '6.a',
    step: Step62,
  },
  {
    id: '7',
    step: Step7,
  },
  {
    id: '8',
    step: Step8,
  },
  {
    id: '9',
    step: Step9,
  },
  {
    id: '10',
    step: Step10,
  },
  {
    id: '11',
    step: Step11,
  },
  {
    id: '12',
    step: Step12,
  },
];

const mapStateToProps = (state) => ({
  step: state.step,
  maxSteps: state.maxSteps,
});

const mapDispatchToProps = (dispatch) => ({
  setMaxSteps: (maxSteps) => dispatch(setMaxSteps(maxSteps)),
});

const ConnectedSurvey = (props) => {
  const { maxSteps } = store.getState();
  props.setMaxSteps(maxSteps);

  return (
    <section className="survey main" id="survey">
      <div className="wrapper">
        <Stepper activeStep={props.step}>
          {STEPS.map((step, index) => {
            const StepName = step.step;
            return (
              <Step key={index} stepId={step.id} title={step.title}>
                <StepName />
              </Step>
            );
          })}
        </Stepper>
      </div>
    </section>
  );
};

const Survey = connect(mapStateToProps, mapDispatchToProps)(ConnectedSurvey);
export default withTranslation('translations')(Survey);
