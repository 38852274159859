import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import { resetSteps, prevStep, nextStep } from '../../store/actions'

import { Button } from '../Button/Button'
import Modal from 'components/Modal/modal'

const mapDispatchToProps = (dispatch) => ({
  reset: () => dispatch(resetSteps()),
  prev: () => dispatch(prevStep()),
  next: () => dispatch(nextStep()),
})

const ConnectedStepperButtons = (props) => {
  const { t } = props
  const resetSteps = () => {
    props.reset() // Update Store
    props.resetSteps() // Update Stepper
  }

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const prevStep = () => {
    props.prev() // Update Store
    props.prevStep() // Update Stepper
  }

  const nextStep = () => {
    props.next() // Update Store
    props.nextStep() // Update Stepper
  }
  let resetButton, prevButton, nextButton
  if (props.resetSteps !== false) {
    resetButton = (
      <Button text={t('steps.buttons.reset')} onClick={resetSteps} />
    )
  }
  if (props.prevStep !== false) {
    prevButton = (
      <Button
        text={t('steps.buttons.prev')}
        link={true}
        icon='chevron-left'
        onClick={prevStep}
      />
    )
  }
  if (props.nextStep !== false) {
    nextButton = (
      <>
        <Button
          text={t('steps.buttons.next')}
          size='normal'
          onClick={props.isOpen ? setIsModalOpen : nextStep}
          disabled={props.nextDisabled}
          dataCy='button-next'
        />

        <Modal open={isModalOpen} onClose={handleModalClose} />
      </>
    )
  }

  return (
    <div className={`step-buttons ${props.className}`}>
      {resetButton}
      {prevButton}
      {nextButton}
    </div>
  )
}

ConnectedStepperButtons.defaultProps = {
  className: '',
  resetSteps: false,
  prevStep: false,
  nextStep: false,
  nextDisabled: true,
}

ConnectedStepperButtons.propTypes = {
  className: PropTypes.string,
  resetSteps: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  prevStep: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  nextStep: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  nextDisabled: PropTypes.bool,
}

const StepButtons = connect(null, mapDispatchToProps)(ConnectedStepperButtons)

export default withTranslation('translations')(StepButtons)
