import React from 'react';
import { withTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';
import PropTypes from 'prop-types';
import ProgressBar from '../ProgressBar/ProgressBar';

export const Balance = (props) => {
  const { t, essential, wants, savings } = props;
  const isMobileDevice = useMediaQuery({ query: '(max-width: 599px)' });

  return (
    <div className="balance">
      <div
        className={cx('balance__content ', {
          'wrapper-small': !isMobileDevice,
        })}
      >
        <div className="balance__content__description">
          <h2 className="t-h3 mb-s mt-s">{t('results.balance.title')}</h2>
          {/* <p className='t-h6 mb-s t-gray'>{t('results.balance.subtitle')}</p> */}
          <p className="t-h6 mb-s t-gray">{t('results.balance.description')}</p>
          <span className="t-h6 mb-s t-gray">{t('results.balance.compare')}</span>
        </div>
        <div
          className={cx('balance__content__progressbar', {
            'wrapper-small': isMobileDevice,
          })}
        >
          <ProgressBar className="mb-s" essential={essential} wants={wants} savings={savings} />
          <ProgressBar type={'secondary'} essential={50} wants={30} savings={20} />
        </div>
      </div>
    </div>
  );
};
Balance.defaultProps = {
  essential: 0,
  wants: 0,
  savings: 0,
};

Balance.propTypes = {
  essential: PropTypes.number.isRequired,
  wants: PropTypes.number.isRequired,
  savings: PropTypes.number.isRequired,
};

export default withTranslation('translations')(Balance);
