export const BASIC_NEEDS = [
  'rent',
  'mortgage',
  'utilities',
  'counciltax',
  'car',
  'publicTransport',
  'mediaEntertainment',
  'insuranceMedical',
  'groceries',
  'childcare',
  'other',
];
export const MONTHLY_DEBTS = ['creditCard', 'loans', 'other'];

export const INSURANCES = ['life', 'criticalIllness', 'incomeProtection', 'home', 'other'];

export const WEEKLY_FACTOR = 4.34;
