import { StyleSheet, View } from '@react-pdf/renderer';
import { useTranslations } from 'hooks';
import React from 'react';
import isEmptyObject from 'utils/isEmptyObject';
import { PDF } from 'constants/pdf';
import { OTHER_TIPS } from 'constants/results';
import TipBlockPdf from './TipBlockPdf';
import TitlePdf from './TitlePdf';

const TipsPdf = ({ C1, C2, C3, C4, C5, C6 }) => {
  const { t } = useTranslations();
  // const getMessages = (type, ratingKey) => {
  //   const key = `results.resilience.${type}.messages.${ratingKey}`;
  //   const messages = [
  //     {
  //       title: t(`${key}.tips.article1.title`),
  //       text: t(`${key}.tips.article1.text`),
  //       link: t(`${key}.tips.article1.link`),
  //     },
  //   ];
  //   return messages;
  // };
  // const [spending] = useState(getMessages('spending', `${C1?.rating}-${C2?.rating}-${C3?.rating}`));
  // const [saving] = useState(getMessages('saving', `${C5?.rating}-${C6?.rating}`));
  // const [borrowing] = useState(getMessages('borrowing', `${C4?.rating}`));

  const localTypeCategory = JSON.parse(localStorage.getItem("storeState"))

  let categoryWorry = localTypeCategory.survey.categoryWorry
  let financialGoal = localTypeCategory.survey.financialGoal
  let typeCategory = !isEmptyObject(categoryWorry)
    ? categoryWorry
    : financialGoal

  const asArray = Object.entries(typeCategory)
  const filtered = asArray.filter(([key, value]) => value === "yes")
  const justStrings = Object.fromEntries(filtered)
  const justStringsFormat = Object.keys(justStrings)

 OTHER_TIPS.filter((tip) => (
    justStringsFormat.map((value) => {
      tip.isSelected = ""
      if (value === tip.id) {
        tip.isSelected = 'yes'
      }
      return tip
    })
 ))
  const formattedTips= OTHER_TIPS.filter(value => value.isSelected === 'yes')

  return (
    <View style={tips.item}>
      <TitlePdf>{t('results.resilience.tips')}</TitlePdf>
      <View style={tips.blocks}>
        <TipBlockPdf  articles={formattedTips} />
      </View>
    </View>
  );
};

export default TipsPdf;

const tips = StyleSheet.create({
  item: {
    display: 'flex',
    flexDirection: 'column',
    color: PDF.color.white,
    paddingVertical: 9,
    paddingHorizontal: 32,
    marginVertical: 5,
    marginHorizontal: 32,
    width: '100%',
  },
  blocks: {
    display: 'flex',
    flexDirection: 'row',
  },
});
