import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

class ScoreContext extends Component {
  render() {
    const { t, score, average } = this.props;

    return (
      <div className="score-context">
        <div className="score-context__info">
          <h2 className="score-context__title t-h4">{t('results.score_context.title')}</h2>
          {/* <div className='score-context__text'>
            {t('results.score_context.text', { average })}
          </div> */}
        </div>
        <div className="score-context__wrapper">
          <div className="score-context__score range">
            <div className="range__row mb-m">
              <div className="range__bar">
                <span
                  className={'range__bar-fill ' + (score === 100 ? 'full' : '')}
                  style={score > average ? { width: `${score}%` } : { width: `${average}%` }}
                ></span>
                <span className={'range__bar-point'} style={{ left: `${score - 2}%` }}></span>
                <span className={'range__bar-point range__bar-point--average '} style={{ left: `${average - 2}%` }}></span>
              </div>
              <div className="range__text__container">
                <div className="range__text">
                  <div className={'range__bar-point--average range__bar-point--small'} />
                  Avg · {average} points
                </div>
                <div className="range__text ">
                  <div className={'range__bar-point range__bar-point--small'} />
                  {t('results.score_context.you')} · {score} points
                </div>
              </div>
            </div>
            <div className="range__row"></div>
          </div>
        </div>
      </div>
    );
  }
}

ScoreContext.defaultProps = {
  score: 0,
  average: 0,
};

ScoreContext.propTypes = {
  score: PropTypes.number,
  average: PropTypes.number,
};

export default withTranslation('translations')(ScoreContext);
