import { StyleSheet, View } from '@react-pdf/renderer';
import { PDF } from 'constants/pdf';
import { useTranslations } from 'hooks';
import React from 'react';
import IconPdf from './IconPdf';
import TextPdf from './TextPdf';

const MonthlySavingsPdf = ({ endMonthSavings, totalSavings }) => {
  const { t } = useTranslations();

  return (
    <View style={monthSavings.item}>
      <TextPdf fontSize={15} color={PDF.color.black} fontWeight="thin">
        {t('pdf.monthlySavings.title_1')}
        <TextPdf fontWeight="medium" color={PDF.color.black} fontSize={15}>
          £{endMonthSavings}
        </TextPdf>
        {t('pdf.monthlySavings.title_2')}
      </TextPdf>
      <View style={monthSavings.blocks}>
        <View style={monthSavings.blocks.block}>
          <View style={monthSavings.blocks.block.name}>
            <IconPdf icon="savingRed" marginRight={5} />
            <TextPdf color={PDF.color.black} fontWeight="thin">
              {t('pdf.monthlySavings.currentAccount')}
            </TextPdf>
          </View>
          <TextPdf color={PDF.color.black} fontWeight="thin">
            £{totalSavings.currentAccount || '0'}
          </TextPdf>
        </View>
        <View style={monthSavings.blocks.block}>
          <View style={monthSavings.blocks.block.name}>
            <IconPdf icon="spendingRed" marginRight={5} />
            <TextPdf color={PDF.color.black} fontWeight="thin">
              {t('pdf.monthlySavings.savingsAccount')}
            </TextPdf>
          </View>
          <TextPdf color={PDF.color.black} fontWeight="thin">
            £{totalSavings.savingsAccount || '0'}
          </TextPdf>
        </View>
      </View>
    </View>
  );
};

export default MonthlySavingsPdf;

const monthSavings = StyleSheet.create({
  item: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  blocks: {
    display: 'flex',
    flexDirection: 'column',
    block: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginVertical: 8,
      name: {
        display: 'flex',
        flexDirection: 'row',
      },
      spend: {
        display: 'flex',
        flexDirection: 'column',
      },
      value: {
        display: 'flex',
        flexDirection: 'row',
      },
    },
  },
});
