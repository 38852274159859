import React, { Component } from 'react';
import { Link as ReactLink } from 'react-router-dom';

import PropTypes from 'prop-types';

export class Link extends Component {
  parseTo(to) {
    let parser = document.createElement('a');
    parser.href = to;
    return parser;
  }

  isInternal(to) {
    // If it's a relative url such as '/path', 'path' and does not contain a protocol we can assume it is internal.

    if (to.indexOf('://') === -1) return true;

    const toLocation = this.parseTo(to);
    return window.location.hostname === toLocation.hostname;
  }

  render() {
    const { to, tab, noBlank, children, dataCy, icon, ...rest } = this.props;
    const isInternal = this.isInternal(to);

    if (isInternal && !tab) {
      return (
        <ReactLink to={to} data-cy={dataCy} {...rest}>
          {children}
        </ReactLink>
      );
    } else if (icon) {
      return (
        <a href={to} rel="noopener noreferrer" target="_blank" {...rest}>
          {children}
          <i className="icon-arrow"></i>
        </a>
      );
    } else if (noBlank) {
      return (
        <a href={to} rel="noopener noreferrer" data-cy={dataCy} {...rest}>
          {children}
        </a>
      );
    } else {
      return (
        <a href={to} rel="noopener noreferrer" target="_blank" {...rest}>
          {children}
        </a>
      );
    }
  }
}

Link.defaultProps = {
  className: 'link',
  tab: false,
  icon: false,
  noBlank: false,
};

Link.propTypes = {
  className: PropTypes.string,
  tab: PropTypes.bool,
  icon: PropTypes.bool,
  noBlank: PropTypes.bool,
};
