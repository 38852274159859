import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { ANALYTICS } from 'constants/analytics';
import { useStepData } from 'hooks';
import { trackView } from 'services/tms';

import Input from '../../Input/Input';
import Step from '../Step';

const STEP_ID = '11';
const PREV_STEP = '10';

const Step11 = ({ t }) => {
  const defaultState = {
    hasInvestments: '',
    investments: '',
  };

  const stepData = useStepData(STEP_ID);
  const data = stepData || defaultState;
  const [state, setState] = useState(data);
  const [nextDisabled, setNextDisabled] = useState(true);

  const updateInvesments = (e) => {
    let value = e.target.value !== '' ? e.target.value : '';
    setState({ ...state, [e.target.name]: value, hasInvestments: 'yes' });
  };

  useEffect(() => {
    // const { payingPension } = store.getState().survey;

    trackView({
      page_url: ANALYTICS.page_url.survey,
      site_section: ANALYTICS.site_section,
      page_lenguage: ANALYTICS.page_language,
      page_type: ANALYTICS.page_type,
      page_customer_group: ANALYTICS.page_customer_group,
      page_security_level: ANALYTICS.page_security_level,
      page_name: ANALYTICS.page_name.survey,
      page_category: ANALYTICS.page_category.survey,
      page_subcategory: ANALYTICS.page_subcategory,
      raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v2,
    });
  }, []);

  useEffect(() => {
    state.investments === '' ? setNextDisabled(true) : setNextDisabled(false);
  }, [state.investments, state.hasInvestments]);

  // const getStepMessage = () => {
  //   const { name } = store.getState().survey;
  //   return <div className="step__message">{t(`steps.step11.messages.${!!state.investments}`, { name })}</div>;
  // };

  return (
    <Step
      data={{ ...state }}
      stepId={STEP_ID}
      prevStep={PREV_STEP}
      nextDisabled={nextDisabled}
      tms={{
        page_url: ANALYTICS.page_url.survey,
        site_section: ANALYTICS.site_section,
        page_lenguage: ANALYTICS.page_language,
        page_type: ANALYTICS.page_type,
        page_customer_group: ANALYTICS.page_customer_group,
        page_security_level: ANALYTICS.page_security_level,
        page_name: ANALYTICS.page_name.survey,
        page_category: ANALYTICS.page_category.survey,
        page_subcategory: ANALYTICS.page_subcategory,
        raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v3,
        form_field_1: 'Step: '+ STEP_ID +'/12',
        event_category: ANALYTICS.event_category.content,
        event_details: ANALYTICS.event_action.detail,
        event_action: ANALYTICS.event_action.button,
      }}
    >
      <div className="step__question">
        <div className="step__flex t-survey">
          <p className="step__flex--title" id={t('steps.step11.part1').replace(/\s/g, '')}>
            {t('steps.step11.part1')}&nbsp;
          </p>
          <p className="step__flex--subtitle" id={t('steps.step11.part1').replace(/\s/g, '')}>
            {t('steps.step11.part2')}&nbsp;
          </p>
          <React.Fragment>
            <p>
              <Input
                type="currency"
                size={7}
                name="investments"
                min="0"
                step=".01"
                value={state.investments}
                onChange={updateInvesments}
                border
                placeholder={t('steps.step11.placeholder')}
                dataCy="input-investments"
              />
            </p>
          </React.Fragment>
        </div>
      </div>
      {/* {state.investments > 0 && getStepMessage()} */}
    </Step>
  );
};

export default withTranslation('translations')(Step11);
