import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { trackLink, trackView } from 'services/tms';
import { ANALYTICS } from 'constants/analytics';
import { SURVEY_PATH } from 'routes';

import { Button } from 'components/Button/Button';

const Home = ({ t }) => {
  const history = useHistory();
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });
  const subtitle = isDesktop ? t('home.hero.subtitleDesktop') : t('home.hero.subtitleMobile');
  const bullets = [t('home.content.bullet1'), t('home.content.bullet2'), t('home.content.bullet3')];

  const trackHeroLink = () => {
    trackLink({
      page_url: ANALYTICS.page_url.home,
      event_category: ANALYTICS.event_category.content,
      event_action: ANALYTICS.event_action.button,
      event_content: 'top:start now',
      page_name: ANALYTICS.page_name.home,
      raw_datalayer: ANALYTICS.raw_datalayer.v2,
    });
  };

  const handleClickCalculateFitness = () => {
    trackHeroLink();
    history.push(SURVEY_PATH);
  };

  useEffect(() => {
    trackView({
      page_url: ANALYTICS.page_url.home,
      site_section: ANALYTICS.site_section,
      page_lenguage: ANALYTICS.page_language,
      page_type: ANALYTICS.page_type,
      page_customer_group: ANALYTICS.page_customer_group,
      page_security_level: ANALYTICS.page_security_level,
      page_name: ANALYTICS.page_name.home,
      page_category: ANALYTICS.page_category.home,
      page_subcategory: ANALYTICS.page_subcategory,
      raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v1,
    });
  }, []);

  return (
    
    <div className="home">
      <section className="hero">
        <div className="hero__data-wrapper" role="img" aria-label="hero-home">
          <h1 className="t-h1 hero__title">{t('home.hero.title')}</h1>
          <p className="hero__subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />
        </div>
      </section>
      <section className="content">
        <div className="content__button-container">
          <Button text={t('generic.start')} size="big" onClick={handleClickCalculateFitness} dataCy="redirect-survey" />
          {isDesktop && <p className="content__goals-text">{t('home.finance.extra')}</p>}
        </div>
        <div className="content__text">
          <div className="content__text-item" dangerouslySetInnerHTML={{ __html: t('home.content.link') }} />
          <ul className="content__bullets">
            {bullets.map((bullet, index) => {
              return <li key={index} className="t-h5 content__text-item" dangerouslySetInnerHTML={{ __html: bullet }} />;
            })}
          </ul>
        </div>
      </section>
    </div>
  );
};

export default withTranslation('translations')(Home);
