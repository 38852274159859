export const PDF = {
  name: 'score.pdf',
  producer: 'HSBC',
  creator: 'HSBC',
  color: {
    primary: '#db0011',
    black: '#000',
    white: '#ffffff',
    bg_page: '#f8f8f8',
    grey: '#767676',
    excellent: '#0d6bbd',
    good: '#00847f',
    bad: '#ffbb33',
    bg_range_bar: '#2c2933',
    range_bar_fill: '#9451e0',
    wants: '#4e9c2d',
    savings: '#00a69d',
    savings_dark: '#006f69',
    bg_survey: '#262626',
    border_select: '#d7d8d6',
    resilience_block: '#e3e3e3',
    pointYellow: '#fecb17'
    
  },
  font: {
    family: 'UniversNextForHSBC',
    default: {
      title: {
        fontSize: 22,
        fontWeight: 'thin',
        lineHeight: 1.5,
      },
      text: {
        fontSize: 12,
        fontWeight: 'light',
      },
    },
    lineHeight: 1.5,
  },
};
