import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { ANALYTICS } from 'constants/analytics';
import { useStepData } from 'hooks';
import { trackView } from 'services/tms';
import { MONTHLY_DEBTS } from '../../../constants/survey';
// import { getSpendingDetailsTrack } from '../../../utils/analytics';
import store from '../../../store';

import CardCustomInput from '../../CardCustomInput/CardCustomInput';
import Step from '../Step';
import CustomDebtCard from 'components/CustomDebtCard/CustomDebtCard';

const STEP_ID = '7';
const PREV_STEP = '6';

const getCleanState = () => {
  return MONTHLY_DEBTS.reduce((prev, current) => {
    return { ...prev, [current]: { total: '', month: '' } };
  }, {});
};

const Step7 = (props) => {
  const { t } = props;
  const defaultState = {
    hasMonthlyDebts: '',
    monthlyDebts: getCleanState(),
  };

  // If we already went through this step, get the data from this step
  // If not, the state will initialize with the default empty state
  const stepData = useStepData(STEP_ID);
  const data = stepData || defaultState;

  const getSelectLabelledBy = () =>
    `${t('steps.step7.line1').replace(/\s/g, '')}
   custom-select-step-${store.getState().step}
    ${t('steps.step7.line1_2').replace(/\s/g, '')}
    ${t('steps.step7.line2').replace(/\s/g, '')}`;

  props = {
    ...{
      select: {
        option: data.hasMonthlyDebts,
        labelledBy: [getSelectLabelledBy()],
        list: [
          { key: 'yes', value: t('steps.step7.option1') },
          { key: 'no', value: t('steps.step7.option2') },
        ],
      },
    },
    ...props,
  };

  // And set it to the state
  const [state, setState] = useState(data);

  // Additional state to check if the next button is disabled
  const [nextDisabled, setNextDisabled] = useState(true);

  // state to check if each debt is valid (has all the info needed)
  const [debtsValid, setDebtsValid] = useState({
    creditCard: false,
    loans: false,
    other: false,
  });

  // state to check if each debt has data
  const [debtsHasData, setDebtsHasData] = useState({
    creditCard: false,
    loans: false,
    other: false,
  });

  const updateSelector = (value) => {
    setState({
      ...state,
      ...{
        hasMonthlyDebts: value,
        monthlyDebts: getCleanState(),
      },
    });
  };

  const updateDebts = (key, value, type) => {
    let stateCopy = { ...state };
    stateCopy.monthlyDebts[key][type] = value;
    setState(stateCopy);
    const debtFilled = isDebtFilled({ ...stateCopy.monthlyDebts[key] });

    setDebtsHasData((prev) => ({
      ...prev,
      [key]: stateCopy.monthlyDebts[key].total !== '' || stateCopy.monthlyDebts[key].month !== '',
    }));

    setDebtsValid((prev) => ({
      ...prev,
      [key]: debtFilled,
    }));
  };

  // check if the element (creditCard, loans or other) has all the info and the total is greater than 0
  const isDebtFilled = (element) => element.total !== '' && element.month !== '' && element.total > 0;

  const isFormValid = useCallback(() => {
    // get how many debts are valid
    const debtsValidLength = Object.entries(debtsValid)
      .map((debt) => debt[1])
      .filter((debt) => debt).length;

    // get how many debts had data filled
    const debtsHasDataLength = Object.entries(debtsHasData)
      .map((debt) => debt[1])
      .filter((debt) => debt).length;

    // if the valids length is equal to hasData length the form is valid
    return debtsValidLength > 0 && debtsValidLength === debtsHasDataLength;
  }, [debtsHasData, debtsValid]);

  // if we went through this step, initialize validation states
  const initValidationState = useCallback(() => {
    if (typeof stepData !== 'undefined') {
      MONTHLY_DEBTS.forEach((key) => {
        if (state.monthlyDebts[key].total !== '' && state.monthlyDebts[key].month !== '') {
          setDebtsHasData((prev) => ({
            ...prev,
            [key]: true,
          }));
          setDebtsValid((prev) => ({
            ...prev,
            [key]: true,
          }));
        }
      });
    }
  }, [state, stepData]);

  useEffect(() => {
    // const { spendBasicNeeds } = store.getState().survey;
    // const totalSpend = Object.values(spendBasicNeeds).reduce((acc, need) => {
    //   return acc + (need.value ? parseFloat(need.value) : 0);
    // }, 0);

    trackView({
      page_url: ANALYTICS.page_url.survey,
      site_section: ANALYTICS.site_section,
      page_lenguage: ANALYTICS.page_language,
      page_type: ANALYTICS.page_type,
      page_customer_group: ANALYTICS.page_customer_group,
      page_security_level: ANALYTICS.page_security_level,
      page_name: ANALYTICS.page_name.survey,
      page_category: ANALYTICS.page_category.survey,
      page_subcategory: ANALYTICS.page_subcategory,
      raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v2,
    });
  }, []);

  useEffect(() => {
    initValidationState();
  }, [initValidationState]);

  //enable-disable next button in step
  useEffect(() => {
    let isNextDisabled = state.hasMonthlyDebts === '' || !(state.hasMonthlyDebts === 'yes' && isFormValid());
    if (state.hasMonthlyDebts === 'no') {
      isNextDisabled = false;
    }

    setNextDisabled(isNextDisabled);
  }, [state, isFormValid]);

  const totalSpend = Object.values(store.getState().survey.spendBasicNeeds).reduce((acc, need) => {
    return acc + (need.value ? parseFloat(need.value) : 0);
  }, 0);

  const totalMonthlyDebts = Object.values(state.monthlyDebts).reduce((acc, need) => {
    return acc + (need.month ? parseFloat(need.month) : 0);
  }, 0);

  const isOpenModal = parseInt(store.getState().survey.monthlyIncome) - (totalSpend + totalMonthlyDebts);

  return (
    <Step
      data={{ ...state }}
      stepId={STEP_ID}
      prevStep={PREV_STEP}
      nextDisabled={nextDisabled}
      isOpenModal={isOpenModal <= 0}
      tms={{
        page_url: ANALYTICS.page_url.survey,
        site_section: ANALYTICS.site_section,
        page_lenguage: ANALYTICS.page_language,
        page_type: ANALYTICS.page_type,
        page_customer_group: ANALYTICS.page_customer_group,
        page_security_level: ANALYTICS.page_security_level,
        page_name: ANALYTICS.page_name.survey,
        page_category: ANALYTICS.page_category.survey,
        page_subcategory: ANALYTICS.page_subcategory,
        raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v3,
        form_field_1: 'Step: '+ STEP_ID +'/12',
        event_category: ANALYTICS.event_category.content,
        event_details: ANALYTICS.event_action.detail,
        event_action: ANALYTICS.event_action.button,
      }}
    >
      <div className="step__question">
        <div className="step__flex">
          <p id={t('steps.step7.line1').replace(/\s/g, '')} className="t-survey step__flex--title">
            {t('steps.step7.line1')}&nbsp;
          </p>
          <div className="step__content-debts">
            <CardCustomInput
              defaultText={props.select.defaultSelectText}
              option={props.select.option}
              selectState={state.hasMonthlyDebts}
              optionsList={props.select.list}
              onSelectChange={updateSelector}
              step={store.getState().step}
              labelledBy={props.select.labelledBy}
              big
              dataCy="monthly-debts-selector"
            />
            {state.hasMonthlyDebts === 'yes' && (
              <div className="step__essential--debts">
                {Object.entries(state.monthlyDebts).map((need, index) => (
                  <CustomDebtCard key={index} need={need} t={t} updateDebts={updateDebts} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {Object.entries(state).hasMonthlyDebts !== 'yes' && (
        <div className="step__tip " dangerouslySetInnerHTML={{ __html: t('steps.step7.tip') }} />
      )}
    </Step>
  );
};

export default withTranslation('translations')(Step7);
