import { BAD, EXCELLENT, GOOD, RATINGS_VALUE } from 'constants/ratings';
import { WEEKLY_FACTOR } from 'constants/survey';

export const formatNumberWith2Decimals = (value) => {
  const valueNumber = +value;
  return valueNumber.toFixed(2).replace(/\.00$/, '');
};

export const getMonthlyValue = (value) => formatNumberWith2Decimals(+value * WEEKLY_FACTOR);

export const getWeeklyValue = (value) => formatNumberWith2Decimals(+value / WEEKLY_FACTOR);

export const deepClone = (object) => JSON.parse(JSON.stringify(object));

// return an array with arrays of the size
export const chunkArray = (array, size = 2) =>
  array.reduce((result, item, index) => {
    const chunkIndex = Math.floor(index / size);

    if (!result[chunkIndex]) {
      result[chunkIndex] = [];
    }
    result[chunkIndex].push(item);
    return result;
  }, []);

export const getWorstRating = (...ratings) => {
  let values = ratings.map((rating) => {
    return RATINGS_VALUE.findIndex((r) => r === rating);
  });
  return RATINGS_VALUE[Math.min(...values)];
};

export const getResilienceBlockMessages = (type, ratingKey, t) => {
  const key = `results.resilience.${type}.messages.${ratingKey}`;
  const messages = {
    top: {
      title: t(`${key}.top.title`),
      text: t(`${key}.top.text`),
      text2: t(`${key}.top.text2`),
      cta: t(`${key}.top.cta`),
      link: t(`${key}.top.link`),
    },
  };

  return messages;
};

export const getMessagesType = (score) => {
  if (score <= 50) {
    return BAD;
  } else if (50 < score && score <= 80) {
    return GOOD;
  } else {
    return EXCELLENT;
  }
};

export const getProtectMessage = (insurances, t) => {
  const insurancesChecked = Object.entries(insurances)
    .filter(([, value]) => value === 'yes')
    .map(([insurance, value]) => ({ insurance, value }));

  // by default show more than one option article
  let key = 'any';
  // if only one is ticked
  if (insurancesChecked.length === 1) {
    // and is home selected, show home article
    if (insurancesChecked[0].insurance === 'home') {
      key = 'home';
      // and is none or other selected, show none article
    } else if (insurancesChecked[0].insurance === 'none' || insurancesChecked[0].insurance === 'other') {
      key = 'none';
    }
  }
  return getResilienceBlockMessages('protect', key, t);
};
