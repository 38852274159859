import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { useStepData } from 'hooks';
import { ANALYTICS } from '../../../constants/analytics';
import { trackView } from '../../../services/tms';
import store from 'store';

import Step from '../Step';
import CardCustomInput from '../../CardCustomInput/CardCustomInput';

const STEP_ID = '12';
const PREV_STEP = '11';
const defaultState = {
    life: '',
    criticalIllness: '',
    incomeProtection: '',
    home: '',
    other: '',
    none: 'none',
};

const Step12 = ({ t }) => {
  const stepData = useStepData(STEP_ID)?.insurances;
  const data = stepData || defaultState;
  const [insurances, setInsurances] = useState(data);
  const [nextDisabled, setNextDisabled] = useState(true);

  const options = [
    { key: 'life', value: 'Life Insurance' },
    { key: 'criticalIllness', value: 'Critical Illness' },
    { key: 'incomeProtection', value: 'Income Protection' },
    { key: 'home', value: 'Home Insurance' },
    { key: 'other', value: 'Other' },
    { key: 'none', value: 'None' },
  ];

  const updateStepData = (e) => {
      if(e.none === 'yes'){
        setInsurances({...defaultState, ...e})
      }else{
        setInsurances({...insurances, ...e, ['none'] : ''})
      }
  };

  useEffect(() => {
    setNextDisabled(!Object.values(insurances).some((value) => value === 'yes'));
  }, [insurances]);

  useEffect(() => {
    // const { payBillsOnTime } = store.getState().survey;
    trackView({
      page_url: ANALYTICS.page_url.survey,
      site_section: ANALYTICS.site_section,
      page_lenguage: ANALYTICS.page_language,
      page_type: ANALYTICS.page_type,
      page_customer_group: ANALYTICS.page_customer_group,
      page_security_level: ANALYTICS.page_security_level,
      page_name: ANALYTICS.page_name.survey,
      page_category: ANALYTICS.page_category.survey,
      page_subcategory: ANALYTICS.page_subcategory,
      raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v2,
    });
  }, []);

  return (
    <Step
      nextDisabled={nextDisabled}
      stepId={STEP_ID}
      data={{insurances: {...insurances}}}
      prevStep={PREV_STEP}
      tms={{
        page_url: ANALYTICS.page_url.survey,
        site_section: ANALYTICS.site_section,
        page_lenguage: ANALYTICS.page_language,
        page_type: ANALYTICS.page_type,
        page_customer_group: ANALYTICS.page_customer_group,
        page_security_level: ANALYTICS.page_security_level,
        page_name: ANALYTICS.page_name.survey,
        page_category: ANALYTICS.page_category.survey,
        page_subcategory: ANALYTICS.page_subcategory,
        raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v3,
        form_field_1: 'Step: '+ STEP_ID +'/12',
        event_category: ANALYTICS.event_category.content,
        event_details: ANALYTICS.event_action.detail,
        event_action: ANALYTICS.event_action.button,
      }}
      calculateStep
    >
      <div className="step__question">
        <div className="step__flex">
          <p className="t-survey step__flex--title">{t('steps.step12.part1')}</p>
        </div>
        <CardCustomInput
          type="checkbox"
          selectState={insurances}
          optionsList={options}
          onSelectChange={updateStepData}
          step={store.getState().step}
          dataCy="select-insurance-policy"
          checkbox
          defaultState={defaultState}
        />
      </div>
    </Step>
  );
};

export default withTranslation('translations')(Step12);
