import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { resetStore } from '../../store/actions';

import Logo from 'assets/images/logo.svg';

const mapDispatchToProps = (dispatch) => ({
  resetStore: () => dispatch(resetStore()),
});

const Header = (props) => {
  const history = useHistory();
  const { close, t } = props;

  const goToHome = (e) => {
    e.preventDefault();
    props.resetStore();
    localStorage.removeItem('scoring');
    history.push('/');
  };

  return (
    <header className="header header__home">
      <a href="/" className="logo" tabIndex="-1" onClick={goToHome}>
        <img src={Logo} alt="HSBC Logo" width={127} height={37} />
      </a>
      {close && (
        <div className="close">
          <button type="button" className="link" onClick={goToHome}>
            {t('generic.close')}
          </button>
        </div>
      )}
    </header>
  );
};

Header.defaultProps = {
  type: 'section',
  close: false,
};

Header.propTypes = {
  type: PropTypes.oneOf(['home', 'section', 'landing', 'howto']),
  close: PropTypes.bool,
};

const HeaderApp = connect(null, mapDispatchToProps)(Header);

export default withTranslation('translations')(HeaderApp);
