import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { ANALYTICS } from 'constants/analytics';
import { useStepData } from 'hooks';
import { trackView } from 'services/tms';
import store from '../../../store';

import CardCustomInput from '../../CardCustomInput/CardCustomInput';
import Step from '../Step';

const STEP_ID = '3';
const PREV_STEP = '2';

const Step3 = (props) => {
  const { t } = props;
  const defaultState = {
    payBillsOnTime: '',
  };

  // If we already went through this step, get the data from this step
  // If not, the state will initialize with the default empty state
  const stepData = useStepData(STEP_ID);
  const data = stepData || defaultState;

  const options = [
    { key: 'always', value: t('steps.step4.option1') },
    { key: 'sometimes', value: t('steps.step4.option2') },
    { key: 'rarely', value: t('steps.step4.option3') },
  ];

  const getSelectLabelledBy = () =>
    `${t('steps.step4.part1').replace(/\s/g, '')}
   custom-select-step-${store.getState().step}
   ${t('steps.step4.part1_2').replace(/\s/g, '')}
   ${t('steps.step4.part2').replace(/\s/g, '')}`;

  props = {
    ...{
      select: {
        showList: false,
        option: data.payBillsOnTime || '',
        list: options,
        labelledBy: [getSelectLabelledBy()],
      },
    },
    ...props,
  };

  // And set it to the state
  const [state, setState] = useState(data);

  // Additional state to check if the next button is disabled
  const [nextDisabled, setNextDisabled] = useState(true);

  const updateStepData = (key, value) => {
    setState({ ...state, [key]: value });
  };

  useEffect(() => {
    // const { hasInvestments, investments } = store.getState().survey;
    trackView({
      page_url: ANALYTICS.page_url.survey,
      site_section: ANALYTICS.site_section,
      page_lenguage: ANALYTICS.page_language,
      page_type: ANALYTICS.page_type,
      page_customer_group: ANALYTICS.page_customer_group,
      page_security_level: ANALYTICS.page_security_level,
      page_name: ANALYTICS.page_name.survey,
      page_category: ANALYTICS.page_category.survey,
      page_subcategory: ANALYTICS.page_subcategory,
      raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v2,
      // funnel_name: ANALYTICS.funnel_name.financialFitnessScore,
      // funnel_step_name: ANALYTICS.funnel_step_name.nameAndAge,
    });
  }, []);

  useEffect(() => {
    // const { stepsCompleted } = store.getState();
    let isNextDisabled = state.payBillsOnTime === '';
    if(state.payBillsOnTime ===  'rarely' || state.payBillsOnTime === 'sometimes') {
      localStorage.setItem("modalExit", true)
    }else{
      localStorage.removeItem("modalExit")
    }
    setNextDisabled(isNextDisabled);
  }, [state.payBillsOnTime]);
  
  const getStepMessage = () => {
    if (state.payBillsOnTime === 'always') {
      return (
        <div
          className="step__message"
          dangerouslySetInnerHTML={{
            __html: t(`steps.step4.messages.${state.payBillsOnTime}`, {
              name: store.getState().survey.name,
            }),
          }}
        ></div>
      );
    }
  };

  return (
    <Step
      data={{ ...state }}
      stepId={STEP_ID}
      prevStep={PREV_STEP}
      nextDisabled={nextDisabled}
      tms={{
        page_url: ANALYTICS.page_url.survey,
        site_section: ANALYTICS.site_section,
        page_lenguage: ANALYTICS.page_language,
        page_type: ANALYTICS.page_type,
        page_customer_group: ANALYTICS.page_customer_group,
        page_security_level: ANALYTICS.page_security_level,
        page_name: ANALYTICS.page_name.survey,
        page_category: ANALYTICS.page_category.survey,
        page_subcategory: ANALYTICS.page_subcategory,
        raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v3,
        form_field_1: 'Step: '+ STEP_ID +'/12',
        event_category: ANALYTICS.event_category.content,
        event_details: ANALYTICS.event_action.detail,
        event_action: ANALYTICS.event_action.button,
      }}
    >
      <div className="step__flex">
        <div className="step__flex step__flex--wrap t-survey">
          <p className="t-survey step__flex--title" id={t('steps.step4.part1').replace(/\s/g, '')}>
            {t('steps.step4.part1')}&nbsp;
          </p>
          <CardCustomInput
            option={props.select.option}
            optionsList={props.select.list}
            selectState={state.payBillsOnTime}
            onSelectChange={(value) => updateStepData('payBillsOnTime', value)}
            step={store.getState().step}
            labelledBy={props.select.labelledBy}
            big
            dataCy="select-bills-payment"
          />
        </div>
      </div>
      {getStepMessage()}
    </Step>
  );
};

export default withTranslation('translations')(Step3);
