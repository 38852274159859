import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { ANALYTICS } from 'constants/analytics';
import { useStepData } from 'hooks';
import { trackView } from 'services/tms';
import store from '../../../store';

import Input from '../../Input/Input';
import Step from '../Step';

const STEP_ID = '8';
const PREV_STEP = '7';

const getMaxSavings = (survey) => {
  const basicNeedSpend = Object.values(survey.spendBasicNeeds).reduce((acc, need) => {
    return acc + (need.value ? parseFloat(need.value) : 0);
  }, 0);
  const debtPayments = Object.values(survey.monthlyDebts).reduce((acc, value) => {
    return acc + (value.month ? parseFloat(value.month) : 0);
  }, 0);
  const essentialSpend = basicNeedSpend + debtPayments;
  const monthlyIncome = survey.monthlyIncome;

  return monthlyIncome - essentialSpend < 0 ? 0 : monthlyIncome - essentialSpend;
};

const Step8 = (props) => {
  const { t } = props;
  const defaultState = {
    monthlySavings: '',
    endMonthSavings: '',
  };

  // If we already went through this step, get the data from this step
  // If not, the state will initialize with the default empty state
  const stepData = useStepData(STEP_ID);
  const data = stepData || defaultState;

  // And set it to the state
  const [state, setState] = useState(data);

  // Calculate max savings
  const survey = store.getState().survey;
  const maxSavings = getMaxSavings(survey).toFixed(2);

  // Additional state to check if the next button is disabled
  const [nextDisabled, setNextDisabled] = useState(true);
  const [isError, setIsError] = useState(false);

  const updateStepData = (e) => {
    let value = e.target.value !== '' ? e.target.value : '';
    setState({ ...state, [e.target.name]: value, monthlySavings: 'yes' });
  };

  useEffect(() => {
    // const { hasMonthlyDebts } = store.getState().survey;
    trackView({
      page_url: ANALYTICS.page_url.survey,
      site_section: ANALYTICS.site_section,
      page_lenguage: ANALYTICS.page_language,
      page_type: ANALYTICS.page_type,
      page_customer_group: ANALYTICS.page_customer_group,
      page_security_level: ANALYTICS.page_security_level,
      page_name: ANALYTICS.page_name.survey,
      page_category: ANALYTICS.page_category.survey,
      page_subcategory: ANALYTICS.page_subcategory,
      raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v2,
    });
  }, []);

  useEffect(() => {
    let isNextDisabled = state.endMonthSavings === '' || parseFloat(state.endMonthSavings) > parseFloat(maxSavings);
    setNextDisabled(isNextDisabled);
    const areSavingsOverMaximum = parseFloat(state.endMonthSavings) > parseFloat(maxSavings);
    // areSavingsOverMaximum &&
    //   trackLink({
    //     page_url: ANALYTICS.page_url.savingDetails,
    //     event_category: ANALYTICS.event_category.error,
    //     event_action: ANALYTICS.event_action.fieldValidation,
    //     event_content: 'saving exceeded maximum',
    //     page_name: ANALYTICS.page_name.savingDetails,
    //     page_subcategory: ANALYTICS.page_subcategory.survey,
    //     raw_datalayer: ANALYTICS.raw_datalayer.v19,
    //     funnel_name: ANALYTICS.funnel_name.financialFitnessScore,
    //     funnel_step_name: ANALYTICS.funnel_step_name.savingDetails,
    //     error_type: ANALYTICS.error_type.fieldValidation,
    //   });
    setIsError(areSavingsOverMaximum);
  }, [maxSavings, state.endMonthSavings]);

  // const getStepMessage = () => {
  //   if (!nextDisabled) {
  //     return (
  //       <div
  //         className="step__message"
  //         dangerouslySetInnerHTML={{ __html: t('steps.step8.message', { name: survey.name }) }}
  //       ></div>
  //     );
  //   }
  // };

  return (
    <Step
      data={{ ...state }}
      stepId={STEP_ID}
      prevStep={PREV_STEP}
      nextDisabled={nextDisabled}
      tms={{
        page_url: ANALYTICS.page_url.survey,
        site_section: ANALYTICS.site_section,
        page_lenguage: ANALYTICS.page_language,
        page_type: ANALYTICS.page_type,
        page_customer_group: ANALYTICS.page_customer_group,
        page_security_level: ANALYTICS.page_security_level,
        page_name: ANALYTICS.page_name.survey,
        page_category: ANALYTICS.page_category.survey,
        page_subcategory: ANALYTICS.page_subcategory,
        raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v3,
        form_field_1: 'Step: '+ STEP_ID +'/12',
        event_category: ANALYTICS.event_category.content,
        event_details: ANALYTICS.event_action.detail,
        event_action: ANALYTICS.event_action.button,
      }}
    >
      <div className="step__flex">
        <p className="t-survey step__flex--title">{t('steps.step8.line1')}</p>
        <p className="t-survey">
          <Input
            title="End Month Savings"
            type="currency"
            size={6}
            name="endMonthSavings"
            min="0"
            step=".01"
            max={maxSavings}
            value={state.endMonthSavings}
            onChange={updateStepData}
            showError={isError}
            errorMessage={t('steps.step8.error') + ' £ ' + maxSavings}
            dataCy="savings-input"
            placeholder={t('steps.step8.placeholder')}
            border
          />
        </p>
      </div>

      <div className="step__tip">
        {t('steps.step8.tip')} <strong>£ {maxSavings}</strong>
      </div>
      {/* {state.endMonthSavings > 0 && <div>{getStepMessage()}</div>} */}
    </Step>
  );
};

export default withTranslation('translations')(Step8);
