import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { ANALYTICS } from 'constants/analytics';
import { useStepData } from 'hooks';
import { trackView } from 'services/tms';
import store from '../../../store';

import CardCustomInput from '../../CardCustomInput/CardCustomInput';
import Step from '../Step';

const STEP_ID = '4';
// const ALT_VARIANT_STEP_2 = '4.2';
const ALT_VARIANT_STEP = '4.a';
const PREV_STEP = '3';

const Step4 = (props) => {
  const { t } = props;
  const defaultState = {
    scope: '',
  };
  // If we already went through this step, get the data from this step
  // If not, the state will initialize with the default empty state
  const stepData = useStepData(STEP_ID);
  const data = stepData || defaultState;

  const getSelectLabelledBy = () =>
    `${t('steps.step3.line1').replace(/\s/g, '')}
     custom-select-step-${store.getState().step}`;

  const options = [
    { key: 'okay_about', value: t('steps.step3.option1') },
    { key: 'worried_about', value: t('steps.step3.option2') },
  ];

  props = {
    ...{
      select: {
        option: data.scope || '',
        list: options,
        labelledBy: [getSelectLabelledBy()],
      },
    },
    ...props,
  };

  // And set it to the state
  const [state, setState] = useState(data);

  // Additional state to check if the next button is disabled
  const [nextDisabled, setNextDisabled] = useState(true);
  const [nextStepId, setNexStepId] = useState(false);

  const updateStepData = (value) => {
    setState({ ...state, scope: value });
  };

  //enable-disable next button in step
  useEffect(() => {
    let isNextDisabled = state.scope === '';
    setNextDisabled(isNextDisabled);

    const variantSteps = () => {
      switch (state.scope) {
        case 'okay_about':
          return ALT_VARIANT_STEP;
        case 'worried_about':
          return false;
        default:
          return false;
      }
    };

    let newNextStep = variantSteps();
    setNexStepId(newNextStep);
  }, [state.scope]);
  useEffect(() => {
    // const { applicant } = store.getState().survey;
    trackView({
      page_url: ANALYTICS.page_url.survey,
      site_section: ANALYTICS.site_section,
      page_lenguage: ANALYTICS.page_language,
      page_type: ANALYTICS.page_type,
      page_customer_group: ANALYTICS.page_customer_group,
      page_security_level: ANALYTICS.page_security_level,
      page_name: ANALYTICS.page_name.survey,
      page_category: ANALYTICS.page_category.survey,
      page_subcategory: ANALYTICS.page_subcategory,
      raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v2,
      // funnel_name: ANALYTICS.funnel_name.financialFitnessScore,
      // funnel_step_name: ANALYTICS.funnel_step_name.nameAndAge,
    });
  }, []);

  return (
    <Step
      data={{ ...state }}
      stepId={STEP_ID}
      prevStep={PREV_STEP}
      nextDisabled={nextDisabled}
      nextStep={nextStepId}
      tms={{
        page_url: ANALYTICS.page_url.survey,
        site_section: ANALYTICS.site_section,
        page_lenguage: ANALYTICS.page_language,
        page_type: ANALYTICS.page_type,
        page_customer_group: ANALYTICS.page_customer_group,
        page_security_level: ANALYTICS.page_security_level,
        page_name: ANALYTICS.page_name.survey,
        page_category: ANALYTICS.page_category.survey,
        page_subcategory: ANALYTICS.page_subcategory,
        raw_datalayer: ANALYTICS.raw_datalayer.new_layer_v2.v3,
        form_field_1: 'Step: '+ STEP_ID +'/12',
        event_category: ANALYTICS.event_category.content,
        event_details: ANALYTICS.event_action.detail,
        event_action: ANALYTICS.event_action.button,
    }}
    >
      <div className="step__question">
        <div className="step__flex">
          <p id={t('steps.step3.line1').replace(/\s/g, '')} className="t-survey step__flex--title">
            {t('steps.step3.line1')}&nbsp;
          </p>
          <CardCustomInput
            defaultText={props.select.defaultSelectText}
            option={props.select.option}
            optionsList={props.select.list}
            onSelectChange={updateStepData}
            selectState={state.scope}
            step={store.getState().step}
            labelledBy={props.select.labelledBy}
            dataCy="select-your-finances"
          />
        </div>
      </div>
    </Step>
  );
};

export default withTranslation('translations')(Step4);
